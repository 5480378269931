import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { alertModal, confirmModal } from 'lib/Modals/alertModal';

import ApiImageUpload from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/admin/ApiImageUpload';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
/* eslint-disable no-param-reassign */
import React from 'react';
import UserDefault from 'lib/UserDefault';
import _ from 'lodash';
import arrayMove from 'array-move';
import { ja } from 'date-fns/locale';
import styled from 'styled-components';

const Handle = SortableHandle(({ value, tag, btnCallback, isHideDelBtn = false }) => (
  <ImageStyle className="uploaded-image__container mx-1 mb-3">
    {isHideDelBtn ? (
      ''
    ) : (
      <CButton
        color="light"
        // className="btn-pill"
        variant="ghost"
        onClick={btnCallback}
      >
        <CIcon name="cil-x" />
      </CButton>
    )}

    {tag === 'episodeContents' ? (
      <img src={value?.imageUrl} style={{ minHeight: '50px', height: 'auto', width: '100px' }} />
    ) : (
      <img src={value?.imageUrl || value?.jpeg} />
    )}
  </ImageStyle>
));

const ImageStyle = styled.div`
  > button {
    border-radius: 5% 0 5% 50%;
    color: gray;
    font-weight: bold;
    transition: background 0.1s, color 0.1s, border-radius 0.1s;
  }
  &:hover {
    > button {
      > svg {
        transition: all 0.6s;
        transform: rotate(270deg);
      }
      background: rgba(104, 104, 104, 0.9);
      box-shadow: -2px 1px 2px rgba(200, 200, 200, 0.9);
      color: white;
    }
  }
`;

const SortableItem = SortableElement(({ tag, value, btnCallback, isHideDelBtn }) => {
  return (
    <>
      <Handle value={value} tag={tag} btnCallback={btnCallback} isHideDelBtn={isHideDelBtn} />
    </>
  );
});

const SortableList = SortableContainer(
  ({ onDelete, token, items, btnCallback, removeUrl, requestBody, tag, isHideDelBtn }, index) => {
    return (
      <div className="d-flex align-content-between flex-wrap">
        {items &&
          items.map((value, index) => {
            return (
              <SortableItem
                key={value?.imageId + index}
                index={index}
                value={value}
                isHideDelBtn={isHideDelBtn}
                tag={tag}
                btnCallback={e => {
                  e.stopPropagation();
                  if (!removeUrl) {
                    onDelete(value);
                    return;
                  }
                  confirmModal(
                    'DELETE',
                    () => {
                      requestBody.imageId = value.imageId;

                      ApiImageUpload.getInstance(UserDefault.getApiDefaultHeaders())
                        .deleteImage(token, removeUrl, requestBody)
                        .then(item => {
                          alertModal('DELETE_AFTER', {
                            title: '이미지 삭제 완료',
                          });
                          btnCallback(value, item);
                        })
                        .catch(error => {
                          console.error('error', error);
                        });
                    },
                    {
                      title: '이미지 삭제 확인',
                      msg: `해당 이미지를 삭제하시겠습니까?`,
                    },
                  );
                }}
              />
            );
          })}
      </div>
    );
  },
);

function SortItemBox({
  storedItems = [],
  token,
  setStoredItem,
  removeUrl,
  requestBody,
  isHideDelBtn = false, // 삭제버튼 노출 조건 추가
  tag,
  onDelete = () => {},
}) {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setStoredItem(arrayMove(storedItems, oldIndex, newIndex));
  };

  return (
    <SortableList
      tag={tag}
      shouldUseDragHandle
      useDragHandle
      distance={1}
      isHideDelBtn={isHideDelBtn}
      token={token}
      items={storedItems}
      onSortEnd={onSortEnd}
      axis="xy"
      removeUrl={removeUrl}
      requestBody={requestBody}
      btnCallback={(value, item) => {
        const result = _.filter(storedItems, o => {
          return o.imageId !== value.imageId;
        });
        setStoredItem(result);
      }}
      onDelete={onDelete}
    />
  );
}

export default SortItemBox;
