import React from 'react';
import { CFormGroup, CCol, CLabel, CInputRadio } from '@coreui/react';

const Radio = ({ index, item }) => {
  return (
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio
        custom
        id={`inline-radio${index}`}
        name={`inline-radio`}
        value={index}
        checked={Number(item.value) === index}
        onChange={e => {
          item.setValue(e.target.value);
        }}
      />
      <CLabel variant="custom-checkbox" htmlFor={`inline-radio${index}`}>
        {item.name}
      </CLabel>
    </CFormGroup>
  );
};

const RadioBox = ({ title, radioItems }) => {
  return (
    <CFormGroup row>
      {title.length > 0 && (
        <CCol md="3">
          <CLabel>{title}</CLabel>
        </CCol>
      )}
      <CCol xs="12" md="9">
        {radioItems.map((c, index) => {
          return <Radio key={index} index={index} item={c} />;
        })}
      </CCol>
    </CFormGroup>
  );
};
export { Radio };
export default RadioBox;
