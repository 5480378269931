import {
  CCol,
  CFormGroup,
  CFormText,
  CInputCheckbox,
  CInputRadio,
  CInvalidFeedback,
  CLabel,
  CSelect,
} from '@coreui/react';

import styled from 'styled-components';

const ChildrenBox = ({
  title = '',
  children,
  formText = '',
  required = false,
  unvisiable = false,
  titleStyle = {},
}) => {
  if (unvisiable) {
    return <></>;
  }

  return (
    <PaymentFormStyle>
      <CFormGroup row className={title === '작품 설정' ? 'rowCon' : ''}>
        <CCol md="3" style={{ ...titleStyle }} className={'colTitCon'}>
          <CLabel htmlFor="text-input">
            {title} {required ? '*' : ''}
          </CLabel>
        </CCol>
        <CCol xs="12" md="9" className={'colContentCon'}>
          {children}
          {required ? (
            <CInvalidFeedback style={{ marginLeft: '5px' }}>
              {`${title} 항목을 입력해주세요`}
            </CInvalidFeedback>
          ) : (
            ''
          )}
          {formText !== undefined && (
            <CFormText style={{ marginLeft: '5px' }} color="muted">
              {formText}
            </CFormText>
          )}
        </CCol>
      </CFormGroup>
    </PaymentFormStyle>
  );
};

export const SelectBox = ({
  value,
  name,
  onChange,
  options,
  disabled = false,
  className = '',
  stringValue,
  style = {},
}) => {
  return (
    <CSelect
      name={name || `select-box`}
      value={value}
      onChange={e => onChange(e)}
      className={className}
      disabled={disabled}
      style={style}
    >
      {options.map((c, index) => {
        if (typeof c === 'object') {
          return (
            <option key={index} value={c.value}>
              {c.label || c.value}
            </option>
          );
        } else {
          return (
            <option key={index} value={stringValue ? c : index}>
              {c}
            </option>
          );
        }
      })}
    </CSelect>
  );
};

export const CheckBox = ({ options, group = 'check' }) => {
  return options.map((item, index) => {
    return (
      <CFormGroup key={`${item.name + index}`} variant="custom-checkbox" inline>
        <CInputCheckbox
          custom
          id={`${group}-${index}`}
          name={`${group}-${index}`}
          checked={item.value || ''}
          onChange={item.setValue ? e => item.setValue(e.target.checked) : item.change}
        />
        <CLabel variant="custom-checkbox" htmlFor={`${group}-${index}`}>
          {item.name}
        </CLabel>
      </CFormGroup>
    );
  });
};

export const RadioBox = ({
  options = { name: '', label: '', color: '#000' },
  value,
  onChange,
  id,
}) => {
  return options.map((item, index) => {
    return (
      <CFormGroup key={`${item.name + index}`} variant="custom-radio" inline>
        <CInputRadio
          custom
          id={`${id}-${index}` || `inline-radio${index}`}
          name={id || `inline-radio`}
          value={index}
          checked={Number(value) === index}
          onChange={onChange}
        />
        <CLabel
          variant="custom-checkbox"
          htmlFor={`${id}-${index}` || `inline-radio${index}`}
          style={{ color: item.color }}
        >
          {item.name}
        </CLabel>
      </CFormGroup>
    );
  });
};
export default ChildrenBox;

const PaymentFormStyle = styled.div`
  .form-group {
    margin: 0;
    > div {
      padding: 10px;
      &:first-of-type {
        display: flex;
        align-items: center;
        label {
          margin: 0;
          color: #333;
        }
      }
      &:nth-of-type(2) {
        div {
          line-height: 1.5;
          /* padding: 7px 12px; */
        }
      }
      input.form-control,
      select.form-control {
        /* width: auto; */
      }
      input.micro-input {
        width: 50px;
        text-align: center;
      }
    }

    &.rowCon {
      flex-direction: column;
      & .colContentCon {
        max-width: 100%;
        flex: unset;
      }
    }
  }
`;
