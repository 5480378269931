import _ from 'lodash';

export const splitNewValue = (value, option, checkValue) => {
  let newValue = value;
  if (option.indexOf('.') !== -1) {
    const options = option.split('.');
    for (let i = 0; options.length > i; i++) {
      if (!newValue) {
        break;
      }
      newValue = newValue[options[i]];
    }
  } else {
    newValue = value[option];
  }
  return newValue || checkValue;
};

const numberParser = (value, option, checkValue) => {
  return _.has(value, option) ? splitNewValue(value, option, checkValue || 0) : checkValue || 0;
};
const stringParser = (value, option, checkValue) => {
  return _.has(value, option) ? splitNewValue(value, option, checkValue || '') : checkValue || '';
};
const arrayParser = (value, option, checkValue) => {
  return _.has(value, option) ? splitNewValue(value, option, checkValue || []) : checkValue || [];
};
const customParser = (value, option, checkValue) => {
  return _.has(value, option) ? splitNewValue(value, option, checkValue) : checkValue;
};
const customParserFn = (value, option, Fn, checkValue) => {
  return _.has(value, option) ? Fn(splitNewValue(value, option, checkValue)) : Fn(checkValue);
};

const parser = {
  Number: numberParser,
  String: stringParser,
  Array: arrayParser,
  Custom: customParser,
  CustomFn: customParserFn,
};
export default parser;
