import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

const options = {
  //localStorage: set key i18nextLng, navigator: set browser language
  order: ['localStorage', 'navigator'], // 우선순위
  lookupLocalStorage: 'i18next',
};

i18next
  .use(LanguageDetector) // 자동으로 언어 가져오기
  .use(initReactI18next) // 자식들 사용할 수 있게.
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./static/locales/${language}/${namespace}.json`)
        .then(resources => {
          callback(null, resources);
        })
        .catch(error => {
          callback(error, null);
        });
    }),
  )
  .init({
    interpolation: { escapeValue: false },
    detection: options,
    load: 'languageOnly',
    fallbackLng: code => {
      if (!code) return ['ko'];
      if (code.includes('ja')) return ['ja'];
      return ['ko'];
    }, // default lan
    supportedLngs: ['ko', 'ja'], // 허용되는 언어
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18next;
