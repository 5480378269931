import ApiAuth, {
  ConsoleUserrIdType,
  ResetPasswordType,
  ResetTokenType,
} from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/admin/ApiAuth';
import UserDefault from 'lib/UserDefault';

import { useSelector } from 'react-redux';
import { NetworkCallback } from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/ApiManager';
import { useState } from 'react';

const useResetPassword = () => {
  const verifyResetToken = (request: ResetTokenType, callback: NetworkCallback) => {
    ApiAuth.getInstance(UserDefault.getApiDefaultHeaders())
      .verifyResetToken(request)
      .then(res => {
        const { data } = res.data;
        callback.onComplete(data);
      })
      .catch(err => {
        callback.onError(err);
      });
  };

  const resetPassword = (request: ResetPasswordType, callback: NetworkCallback) => {
    ApiAuth.getInstance(UserDefault.getApiDefaultHeaders())
      .resetPasswrod(request)
      .then(res => {
        const { data } = res.data;
        callback.onComplete(data);
      })
      .catch(err => {
        callback.onError(err);
      });
  };

  return { verifyResetToken, resetPassword };
};

export default useResetPassword;
