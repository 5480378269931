import useInput from 'lib/hooks/useInput';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DepartmentList } from './statisticsv/_constants';
import { SelectBoxV2 } from './template';

const InputField = ({ formik, name, label, type, className, ...rest }: any) => {
  if (formik) {
    const { values, handleChange, handleSubmit, errors, touched } = formik;
    return (
      <InputFieldStyle className={className}>
        {label && <label htmlFor={name}>{label}</label>}
        <div>
          <input
            type={type || 'text'}
            name={name}
            values={values}
            onChange={handleChange}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit();
              }
            }}
            autoFocus
            {...(rest as any)}
          />
          <p>{errors[name] && touched[name] && errors[name]}</p>
        </div>
      </InputFieldStyle>
    );
  } else {
    return <>formik을 넘겨주세요</>;
  }
};

export const DepartmentField = ({ formik, name, label, type, className, ...rest }: any) => {
  if (formik) {
    const { values, handleChange, handleSubmit, errors, touched } = formik;
    const [category, setCategory] = useState(0); // 부서 대분류
    const [departmentList, setDepartmentList] = useState<any[]>([]); // 부서 중분류
    const [department, handleDepartment, setDepartment] = useInput('');

    // 대분류 선택 시 중분류 리스트 설정
    useEffect(() => {
      if (Number(category) === 0) {
        setDepartment('');
      } else {
        setDepartmentList(
          DepartmentList[category - 1].list.map(v => {
            return { label: v, value: v };
          }),
        );
      }
    }, [category]);

    // 중분류 리스트 변경되면 기본값 index 0으로 설정
    useEffect(() => {
      if (category > 0) setDepartment(departmentList[0]?.value);
    }, [departmentList]);

    // 중분류 선택 시 values의 department 값 변경
    useEffect(() => {
      values.department = department;
    }, [department]);

    return (
      <InputFieldStyle className={className}>
        {label && <label htmlFor={name}>{label}</label>}
        <div>
          <SelectBoxV2
            style={{
              marginRight: '10px',
            }}
            className="select-box"
            value={category}
            onChange={(e: any) => {
              setCategory(e.target.value);
            }}
            options={['=== 선택 ==='].concat(DepartmentList.map((v: any) => v.department))}
          />
          {category > 0 &&
            (DepartmentList[category - 1].department === '기타' ? (
              <input
                type={type || 'text'}
                style={{ width: '200px', display: 'inline-block' }}
                name={name}
                values={values}
                onChange={handleChange}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
                autoFocus
                {...(rest as any)}
              />
            ) : (
              <SelectBoxV2
                className="select-box"
                name={name}
                value={department}
                onChange={handleDepartment}
                options={departmentList}
              />
            ))}

          <p>{errors[name] && touched[name] && errors[name]}</p>
        </div>
      </InputFieldStyle>
    );
  } else {
    return <>formik을 넘겨주세요</>;
  }
};

export default InputField;

const InputFieldStyle = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  > label {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 20px;
    /* width: 100px; */
    flex: 0 0 18%;
    word-break: keep-all;

    @media (max-width: 767px) {
      flex: 0 0 25%;
    }
  }
  > div {
    width: 100%;
    > input {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      border: 1px solid;
      color: #5c6873;
      background-color: #fff;
      border-color: #e4e7ea;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    > p {
      min-height: 18px;
      margin-top: 2px;
      margin-left: 6px;
      margin-bottom: 0px;
      font-size: 0.75rem;
      color: red;
    }
  }
  .select-box {
    width: 150px;
    display: inline-block;
  }
`;
