import { createSlice } from '@reduxjs/toolkit';

export const name = 'ViewSlice';

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
};

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    setSideBarShow: (state, action) => {
      state.sidebarShow = action.payload;
    },
    setAsideShow: (state, action) => {
      state.asideShow = action.payload;
    },
    setDarMode: (state, action) => {
      state.darkMode = action.payload;
    },
  },
});

export const { setSideBarShow, setAsideShow, setDarMode } = slice.actions;

export default slice.reducer;
