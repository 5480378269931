// Add style manually
import 'react-upload-gallery/dist/style.css'; // or scss

import RUG, { DropArea } from 'react-upload-gallery';
import {
  decreaseContentsUploadCount,
  endContentsUpload,
  increaseContentsUploadCount,
} from '../store/slices/imgQueue';

import ApiImageUpload from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/admin/ApiImageUpload';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import UserDefault from 'lib/UserDefault';
import _ from 'lodash';
import { alertModal } from 'lib/Modals/alertModal';
import axios from 'axios';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

const LIMIT_SIZE = 1024 * 1024 * 5;

function ImageUpload({
  token,
  imageType,
  updateItem,
  requestForm,
  onUploadComplete,
  callbackUrl,
  removeRequest,
  imageContent,
  removeUrl,
  type,
  limitCount = 0,
}) {
  const dispatch = useDispatch();

  // ANCHOR: request 생성
  const customRequest = ({ uid, file, send, action, headers, onProgress, onSuccess, onError }) => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    if (imageType === 'banner' && imageContent.length > 0) {
      alertModal('ERROR', {
        title: `한개의 이미지만 등록해주세요`,
        msg: '기존 이미지 삭제 후, 새로운 이미지를 등록해주세요.',
      });
    }

    const progressCallback = {
      onUploadProgress: ({ total, loaded }) => {
        onProgress(uid, Math.round((loaded / total) * 100));
      },
      cancelToken: source.token,
    };

    if (
      (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') &&
      file.size > LIMIT_SIZE
    ) {
      dispatch(endContentsUpload());
      onError(uid, {
        action,
        status: 1000,
        response: '파일 5MB 초과',
      });
      return {
        abort() {
          source.cancel();
        },
      };
    }

    const form_data = new FormData();

    for (const key in requestForm) {
      form_data.append(key, requestForm[key]);
    }
    form_data.append('file', file);

    if (imageType === 'episodeContents') {
      dispatch(increaseContentsUploadCount());
    }

    ApiImageUpload.getInstance(UserDefault.getApiDefaultHeaders())
      .uploadImage(token, callbackUrl, form_data)
      .then(response => {
        const data = response.data.data;
        const uploadItem = {
          imageId: data.imageId,
          imageUrl: data.imageUrl,
        };
        console.log('data', data);
        // 신규 아이템 처리
        if (type === 'single') {
          updateItem(uploadItem);
        } else {
          updateItem(imageContent.concat(uploadItem));
        }

        onSuccess(uid, uploadItem);
      })
      .catch(error => {
        console.error(error);
        dispatch(endContentsUpload());
        onError(uid, {
          action,
          status: error.request,
          response: error.response,
        });
      });

    return {
      abort() {
        source.cancel();
      },
    };
  };

  const onDelete = e => {
    e.stopPropagation();
    if (removeUrl === '') return;
    ApiImageUpload.getInstance(UserDefault.getApiDefaultHeaders())
      .deleteImage(token, removeUrl, removeRequest)
      .then(item => {
        updateItem([]);
      })
      .catch(error => {
        console.error('error', error);
      });
  };
  const oneImg = imageContent?.imageUrl;
  const rules = limitCount > 0 ? { limit: limitCount } : {};
  return (
    <RUG
      rules={rules}
      accept={['jpg', 'jpeg', 'png']}
      inOrder={false}
      onSuccess={img => {
        img.remove();
        if (imageType === 'episodeContents') {
          dispatch(decreaseContentsUploadCount());
        }
      }}
      onWarning={(type, rules) => {
        switch (type) {
          case 'limit':
            alertModal('ERROR', {
              title: `이미지는 한번에 1개만 올려주세요.`,
            });
          default:
        }
      }}
      onError={err => {
        console.error('onError', err);

        if (err.response?.data.error?.includes('permission error')) {
          return alertModal('ERROR', {
            title: '이미지 업로드 권한 에러',
            msg: '이미지 업로드 권한이 없습니다. 최고 관리자에게 문의해주세요.',
          });
        }

        alertModal('ERROR', {
          title: `${err.image.name} 파일 업로드 중 오류가 발생하였습니다. (${err.response})`,
        });
      }}
      onDeleted={img => {
        console.log('delete images ', img);
      }}
      autoUpload
      customRequest={customRequest}
      initialState={[]}
      type="list"
      onChange={images => {
        // count 0 시 완료 처리로 보여짐
        if (images.length === 0) {
          if (typeof onUploadComplete === 'function') {
            onUploadComplete();
          }
        }
      }}
      header={({ openDialogue }) => (
        <DropArea>
          {isDrag =>
            type === 'single' ? (
              <DropAreaInner requestForm={requestForm} image={oneImg} onClick={openDialogue}>
                {oneImg ? (
                  <>
                    <img className="uploadImage" src={oneImg} />
                    <div className="deleteButton" onClick={onDelete}>
                      삭제
                    </div>
                    <span className="info">이미지를 드롭하거나 버튼을 눌러주세요</span>
                  </>
                ) : (
                  <span>이미지를 드롭하거나 버튼을 눌러주세요</span>
                )}
              </DropAreaInner>
            ) : (
              <div
                style={{
                  background: isDrag ? '#fff' : '#fff',
                  border: '3px dashed #ddd',
                  padding: '16px',
                  textAlign: 'center',
                }}
              >
                <CButton
                  className="btn-lg btn-square"
                  size="large"
                  color="secondary"
                  onClick={openDialogue}
                >
                  <CIcon name="cil-cloud-upload" />
                  <span className="mx-1" />
                  이미지를 드롭하거나 버튼을 눌러주세요
                </CButton>
              </div>
            )
          }
        </DropArea>
      )}
    />
  );
}

export default ImageUpload;

const DropAreaInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #fff; */
  position: relative;
  border: 3px dashed #eef;
  cursor: pointer;
  min-height: 150px;
  &:hover {
    border: 3px dashed #ddd;
    transition: border 0.6s;
    .info {
      transition: opacity 0.6s;
      opacity: ${({ image }) => (image ? 1 : 0)};
      z-index: 13;
    }
  }
  padding: 16px;
  text-align: center;
  height: 100%;
  > button {
    z-index: 10;
  }
  .uploadImage {
    z-index: 12;
    border: 1px solid gray;
    width: ${({ requestForm }) => (requestForm?.width ? `${requestForm.width}px` : 'auto')};
    height: ${({ requestForm }) => (requestForm?.height ? `${requestForm.height}px` : 'auto')};
    width: 30%;
    height: 30%;
    @media screen and (max-width: 1000px) {
    }
  }
  .deleteButton {
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 10px;
    position: absolute;
    right: 0;
    height: 100%;
    background: #e55353;
    color: white;
    &:hover {
      background: #e03232;
    }
  }
  > .info {
    opacity: 0;
    position: absolute;
    background: white;
    padding: 10px 15px;
    border: 1px solid gray;
    font-size: 1.1rem;
    border-radius: 4px;
    color: #333;
  }
  &::before {
    content: '';
    background-image: url(${({ image }) => image});
    background-size: 100% 100%;
    opacity: 0.09;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .mx-1 {
  }
`;
