import { createSlice } from '@reduxjs/toolkit';

export const name = 'ToastSlice';

const initialState = {
  message: '',
};

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

// export const sendMessageReducers =

export const { setMessage } = slice.actions;

export default slice.reducer;
