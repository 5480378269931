import React from 'react';
import { CFormGroup, CCol, CLabel, CInput, CFormText } from '@coreui/react';

/**
 * Input Text view
 * @title : 제목
 * @inputData : inputBox에 저장될 값
 * @setInputData : 원본 상태를 변경시킬 값
 * @constructor created by jadejun 2021.05.14
 */
const InputBox = ({
  title,
  inputData,
  setInputData,
  placeholder,
  formText,
  isDisable,
  ...rest
}) => {
  return (
    <CFormGroup row>
      <CCol md="3">
        <CLabel htmlFor="input-box">{title}</CLabel>
      </CCol>
      <CCol xs="12" md="9">
        <CInput
          id="input-box"
          name="input-box"
          value={inputData || ''}
          placeholder={placeholder !== undefined ? placeholder : ''}
          disabled={isDisable === undefined ? false : isDisable}
          onChange={e => {
            setInputData !== undefined && setInputData(e.target.value);
          }}
        />
        {formText !== undefined && <CFormText color="muted">{formText}</CFormText>}
      </CCol>
    </CFormGroup>
  );
};

export default InputBox;
