import {
  CButton,
  CButtonToolbar,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CRow,
} from '@coreui/react';
import React, { useMemo, useState } from 'react';

import CIcon from '@coreui/icons-react';
import FileUpload from 'components/FileUpload';
import ImageResizeNotice from 'components/ImageResizeNotice';
import ImageUpload from 'components/ImageUpload';
import ImageUploadV2 from 'components/ImageUploadV2';
import SortItemBox from 'components/SortItemBox';

/**
 * 관리자 파일 업로드 Form
 * @param title : 제목
 * @param token : 인증 token
 * @param requestForm : file upload시 파일 정보 (width 등등)
 * @param imageContent : image 정보 리스트
 * @param setImagContent : image 정보리스트 set
 * @param removeUrl : 이미지 삭제시 요청할 url
 * @param removeRequest : 이미지 삭제시 전문 정보
 * @param uploadUrl : 파일 업로드 url
 * @param tag : tag
 * @param onUploadComplete : file upload 모두 완료시 callback
 * @param showViewer : 이미지 viewer로 보기 callback function
 * @param onSortSave : 정렬 저장 버튼 callback function
 * @returns {JSX.Element}
 * @constructor created by jadejun 2021.05.14
 */
const ImageUploadForm = props => {
  const {
    title,
    token,
    requestForm,
    imageContent,
    setImagContent,
    removeUrl,
    removeRequest,
    uploadUrl,
    tag,
    isHideDelBtn = false, // 이미지 삭제 버튼 노출 / 비노출 조건
    onUploadComplete,
    onSortSave,
    onDeleteAll,
    onDelete,
    logoNotice,
    limitCount = 0,
  } = props;

  let fileUploadRequestForm = Object.assign({}, requestForm);
  if (fileUploadRequestForm.width) {
    delete fileUploadRequestForm.width;
  }

  return (
    <CFormGroup>
      <CCardHeader htmlFor="name">
        {title}
        {logoNotice && (
          <div className="card-header-actions">
            <small>{logoNotice}</small>
          </div>
        )}
        <ImageResizeNotice {...requestForm} />

        <CRow>
          <CCol xs="12" className="my-2">
            <CButtonToolbar justify="end">
              <CButtonToolbar justify="start">
                {onDeleteAll !== undefined && (
                  <CButton className="mr-1" size="sm" color="danger" onClick={e => onDeleteAll(e)}>
                    <CIcon name="cil-trash" />
                    <span className="mx-1" />
                    이미지 전체 삭제
                  </CButton>
                )}
              </CButtonToolbar>

              {onSortSave !== undefined && (
                <CButton type="submit" size="sm" color="info" onClick={onSortSave}>
                  <CIcon name="cil-scrubber" /> 이미지 정렬 저장
                </CButton>
              )}
            </CButtonToolbar>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <>
          <CCol xs="12" md="12" className={`${tag}-image__container`}>
            <SortItemBox
              tag={tag}
              token={token}
              isHideDelBtn={isHideDelBtn}
              storedItems={imageContent}
              setStoredItem={setImagContent}
              requestBody={removeRequest}
              removeUrl={removeUrl}
              onDelete={onDelete}
            />
          </CCol>
          <CCol xs="12">
            <ImageUpload
              token={token}
              imageContent={imageContent}
              updateItem={setImagContent}
              onUploadComplete={onUploadComplete}
              requestForm={requestForm}
              callbackUrl={uploadUrl}
              imageType={tag}
              limitCount={limitCount}
            />
          </CCol>
        </>
      </CCardBody>
    </CFormGroup>
  );
};

export default ImageUploadForm;
