import _ from 'lodash';
const { version } = require('../../package.json');
// 선물함 대상값
export type GiftTarget = {
  code?: string;
  loginId: string;
} | null;

interface BitListType {
  permission: [originalLabel: string, label: string, bit: number];
  language: [label: string, bit: number];
}
// TODO: low code split
export default class UserDefault {
  static getApiDefaultHeaders(language?: string) {
    const packageVersion = version;
    const result = {
      version: packageVersion,
      apiKey: `${process.env.REACT_APP_API_KEY}`,
      baseUrl: `${process.env.REACT_APP_BASE_URL}`,
      uuid: '',
      partnerCode: null,
      language,
    };

    return result;
  }

  static getApiCRAHeaders() {
    const packageVersion = version;
    const result = {
      version: packageVersion,
      apiKey: `${process.env.REACT_APP_API_KEY}`,
      baseUrl: `${process.env.REACT_APP_BASE_URL_CRA}`,
      uuid: '',
      partnerCode: null,
    };

    return result;
  }

  static languageIcons = [
    { icon: 'cif-us', language: 'en' },
    { icon: 'cif-kr', language: 'ko' },
    { icon: 'cif-es', language: 'es' },
    { icon: 'cif-jp', language: 'ja' },
  ];
  static getServerVersion(n: number) {
    const key = n === 1 ? '' : '2';
    const serverVersion = localStorage.getItem(`sVersion${key}`);
    if (serverVersion) {
      return serverVersion;
    }
    return '';
  }

  // 임시생성,현재 안쓰임
  static getPersistStorageData = (key?: string) => {
    const PERSIST_KEY = 'persist:GTA';
    const result =
      localStorage.getItem(PERSIST_KEY) && JSON.parse(localStorage.getItem(PERSIST_KEY) || '');
    if (key) {
      return JSON.parse(result?.[key]) || {};
    } else {
      result;
      return result || {};
    }
  };

  static bitList = {
    getter: (key?: 'language' | 'permission'): BitListType => {
      const result =
        localStorage.getItem('bitList') && JSON.parse(localStorage.getItem('bitList') || '');
      if (key) {
        return result?.[key];
      } else {
        return result;
      }
    },
    setter: (data: BitListType) => {
      if (data?.language.length > 0 && data?.permission.length > 0) {
        localStorage.setItem('bitList', JSON.stringify(data));
      } else if (!data) {
        localStorage.removeItem('bitList');
      }
    },
    find: (key: 'language' | 'permission', label?: string) => {
      const result =
        localStorage.getItem('bitList') && JSON.parse(localStorage.getItem('bitList') || '');
      if (result?.[key]) {
        return result?.[key].find((item: any) => item.label === label)?.bit;
      }
    },
    permissionCheck: async (label: string, userPermission: number) => {
      const categoryBit = await this.bitList.find('permission', label);
      if (categoryBit) {
        return this.BigIntBitAnd(userPermission, categoryBit) !== 0 || userPermission === 1;
      }
    },
  };

  static BigIntBitAnd = (sum: number, bit: number) => {
    if (sum === 0) {
      return 0;
    }
    if (sum && bit) {
      const BigIntBit = BigInt(sum) & BigInt(bit);
      return Number(BigIntBit);
    }
  };
}
