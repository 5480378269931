// Add style manually
import 'react-upload-gallery/dist/style.css'; // or scss

import ApiImageUpload from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/admin/ApiImageUpload';
import CIcon from '@coreui/icons-react';
import UserDefault from 'lib/UserDefault';
import _ from 'lodash';
import { alertModal } from 'lib/Modals/alertModal';
import axios from 'axios';
import { setLoading } from 'store/slices/support/rendering';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

const LIMIT_SIZE = 1024 * 1024 * 5;

function ImageUpload({ token, updateItem, requestForm, onUploadComplete, callbackUrl }) {
  const dispatch = useDispatch();

  // ANCHOR: request 생성
  const customRequest = (acceptedFiles, fileRejections) => {
    try {
      if (fileRejections.length > 0) {
        fileRejections.map(file => {
          onError(file.errors[0].code, file);
        });
        return;
      }

      if (acceptedFiles.length > 0) {
        dispatch(setLoading(true));
        const form_data = new FormData();

        // request 에 comicId, episodeId 추가
        for (const key in requestForm) {
          form_data.append(key, requestForm[key]);
        }

        for (let file of acceptedFiles) {
          form_data.append('files', file);
        }
        ApiImageUpload.getInstance(UserDefault.getApiDefaultHeaders())
          .uploadEpisodeImage(token, callbackUrl, form_data)
          .then(async response => {
            const data = response.data.data;
            let images = data;
            if (_.has(data, 'images')) {
              images = data.images;
            }
            const uploadItem = images.map(v => {
              return {
                ...v,
                source: v.imageUrl,
              };
            });
            // 신규 아이템 처리
            updateItem(prevState => [...prevState, ...uploadItem]);
            dispatch(setLoading(false));
          })
          .catch(error => {
            dispatch(setLoading(false));
            console.log(error);
            // onError(error.response?.data.error, file);
          });
      }
      // }
    } catch {
      dispatch(setLoading(false));
      throw new Error('episode upload failed');
    }
  };

  /**
   *
   * @param {*} error
   * @param file : undefined
   * @returns
   */
  const onError = (error, file) => {
    if (typeof error !== 'string') return;
    // if (error && error.includes('permission error')) {
    //   return alertModal('ERROR', {
    //     title: '이미지 업로드 권한 에러',
    //     msg: '이미지 업로드 권한이 없습니다. 최고 관리자에게 문의해주세요.',
    //   });
    // }

    if (error.includes('file-invalid-type')) {
      return alertModal('ERROR', {
        title: `${file.file.name} 파일 업로드 중 오류가 발생하였습니다.`,
        msg: '이미지 파일만 업로드해주세요',
      });
    }
    if (error.includes('file-too-large')) {
      return alertModal('ERROR', {
        title: `${file.file.name} 파일 업로드 중 오류가 발생하였습니다.`,
        msg: '파일 5MB 초과',
      });
    }
    return alertModal('ERROR', {
      title: `${file.file.name} 파일 업로드 중 오류가 발생하였습니다.`,
    });
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    onDrop: customRequest,
    maxSize: LIMIT_SIZE,
  });

  return (
    <DropAreaInner {...getRootProps()}>
      <input {...getInputProps()} />
      <div>
        <CIcon name="cil-cloud-upload" />
        <span>이미지 파일을 드롭하거나 버튼을 눌러주세요</span>
      </div>
    </DropAreaInner>
  );
}

export default ImageUpload;

const DropAreaInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #fff; */
  position: relative;
  border: 3px dashed #eef;
  cursor: pointer;
  min-height: 150px;
  &:hover {
    border: 3px dashed #ddd;
    transition: border 0.6s;
    .info {
      transition: opacity 0.6s;
      z-index: 13;
    }
  }
  padding: 16px;
  text-align: center;
  height: 100%;
  > button {
    z-index: 10;
  }
  .uploadImage {
    z-index: 12;
    border: 1px solid gray;
    width: 30%;
    height: 30%;
    @media screen and (max-width: 1000px) {
    }
  }
  .deleteButton {
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 10px;
    position: absolute;
    right: 0;
    height: 100%;
    background: #e55353;
    color: white;
    &:hover {
      background: #e03232;
    }
  }
  > .info {
    opacity: 0;
    position: absolute;
    background: white;
    padding: 10px 15px;
    border: 1px solid gray;
    font-size: 1.1rem;
    border-radius: 4px;
    color: #333;
  }
  &::before {
    content: '';
    background-size: 100% 100%;
    opacity: 0.09;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .mx-1 {
  }
`;
