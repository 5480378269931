import CIcon from '@coreui/icons-react';
import UserDefault from 'lib/UserDefault';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import ApiImageUpload from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/admin/ApiImageUpload';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/slices/support/rendering';
import { alertModal } from 'lib/Modals/alertModal';

const LIMIT_SIZE = 1024;

const FileUpload = ({ token, requestForm, updateItem, callbackUrl }: any) => {
  const dispatch = useDispatch();
  const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {
    dispatch(setLoading(true));

    if (fileRejections.length > 0) {
      dispatch(setLoading(false));
      fileRejections.map((file: any) => {
        onError(file.errors[0].code, file);
      });
      return;
    }

    const form_data = new FormData();
    for (const key in requestForm) {
      form_data.append(key, requestForm[key]);
    }
    form_data.append('file', acceptedFiles[0]);
    ApiImageUpload.getInstance(UserDefault.getApiDefaultHeaders())
      .uploadImage(token, '/api/v3/upload/epub', form_data)
      .then(response => {
        const data = response.data.data;
        const EpubImages = data.map((v: any) => {
          return {
            ...v,
            source: v.imageUrl,
          };
        });
        // 신규 아이템 처리
        updateItem((prevState: any) => [...prevState, ...EpubImages]);
        dispatch(setLoading(false));
        // onSuccess(uid, uploadItem);
      })
      .catch(error => {
        console.error(error);
        dispatch(setLoading(false));
        // dispatch(endContentsUpload());
        // onError(uid, {
        //   action,
        //   status: error.request,
        //   response: error.response,
        // });
      });
  }, []);

  const onError = (error: any, file: any) => {
    if (error.includes('file-invalid-type')) {
      return alertModal('ERROR', {
        title: `${file.file.name} 파일 업로드 중 오류가 발생하였습니다.`,
        msg: 'epub 파일만 업로드해주세요.',
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/epub+zip',
    maxFiles: 1,
    onDrop,
  });

  return (
    <DropAreaInner {...getRootProps()}>
      <input {...getInputProps()} />
      <div>
        <CIcon name="cil-cloud-upload" />
        <span>epub 파일을 드롭하거나 버튼을 눌러주세요</span>
      </div>
    </DropAreaInner>
  );
};

export default FileUpload;

const DropAreaInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border: 3px dashed rgb(221, 221, 221);
  padding: 16px;
  text-align: center;
  /* min-height: 150px; */
  > div {
    display: inline-block;
    font-weight: 400;
    font-size: 0.9rem;
    text-align: center;
    vertical-align: middle;
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border: 0;
    color: #4f5d73;
    background-color: #ced2d8;
    box-shadow: 0 1px 1px 0 rgba(var(--elevation-base-color), 0.14),
      0 2px 1px -1px rgba(var(--elevation-base-color), 0.12),
      0 1px 3px 0 rgba(var(--elevation-base-color), 0.2);
    &:hover {
      color: #4f5d73;
      background-color: #b9bec7;
    }
    > span {
      margin-left: 0.5rem;
    }
  }
`;
