import { ObjectModel } from 'objectmodel';

export const SignModel = new ObjectModel({
  date: String,
  signUp: Number,
  signUpPercent: [String, Number],
});

export const ComicModel = new ObjectModel({
  comic: Number,
  comicName: String,
  koTitle: [String, null],
  pc: Number,
  mobile: Number,
  episodeCount: Number,
  etc: Number,
  depth2: String,
  viewCount: Number,
  weekly: String,
  coin: Number,
  pcCoin: Number,
  mobileCoin: Number,
  etcCoin: Number,
});

export const EpisodeModel = new ObjectModel({
  weekly: String,
  comicName: String,
  episodeTitle: String,
  viewCount: Number,
  pc: Number,
  mobile: Number,
  etc: Number,
  coin: Number,
  pcCoin: Number,
  mobileCoin: Number,
  etcCoin: Number,
});

export const PromotionCodeModel = new ObjectModel({
  code: String,
  total: Number,
  codeName: [String, null],
  signUpCount: Number,
  signUpRate: Number,
  loginCount: Number,
  paymentCount: Number,
  paymentAmount: Number,
  paymentRate: Number,
  currencyCode: String,
});

export const PromotionDayModel = new ObjectModel({
  date: String,
  total: Number,
  signUpCount: Number,
  signUpRate: Number,
  loginCount: Number,
  paymentCount: Number,
  paymentAmount: Number,
  paymentRate: Number,
  currencyCode: String,
});

export const AllUserCoinModel = new ObjectModel({
  typeName: String,
  totalCoin: Number,
  useCoin: Number,
  expiredCoin: Number,
  isExpireCoin: Boolean,
  delCoin: Number,
  remainingCoin: Number,
  usePercent: Number,
});
