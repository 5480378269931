import { useState, useCallback } from 'react';

// Input Hooks
const useInput = initialState => {
  const [value, setValue] = useState(initialState);

  const handleValue = e => {
    setValue(e.target.value);
  };

  return [value, handleValue, setValue];
};
// 숫자만 입력 가능한 Input Hooks
export const useNumberInput = initialState => {
  const [value, setValue] = useState(initialState);

  const handleValue = e => {
    const targetValue = e.target.value.replace(/[^0-9\.]/g, '');
    // const comma = targetValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    setValue(targetValue);
  };

  return [value, handleValue, setValue];
};

export const useToggle = initialState => {
  // 상태값을 toggle 시켜주는 Hooks , 나중에 파일 분리하기
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState(prev => !prev));

  return [state, toggle];
};

export default useInput;
