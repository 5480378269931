/* eslint-disable no-param-reassign */
import { DefaultTheme } from 'styled-components';

const color = {
  //coreUI color-base
  primary: '#321fdb',
  secondary: '#9da5b1',
  success: '#e7fff1',
  info: '#39f',
  warning: '#f9b115',
  danger: '#e55353',
  light: '#ebedef',
  dark: '#4f5d73',

  white: '#ffffff',
  black: '#000015',
  blue: '#0d6efd',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#d63384',
  red: '#dc3545',
  orange: '#fd7e14',
  yellow: '#ffc107',
  green: '#198754',
  teal: '#20c997',
  cyan: '#0dcaf0',
  gray: '#8a93a2',
  gray_dark: '#636f83',
};

const deviceSizeList = {
  mobile: 320,
  tablet: 768,
  laptop: 1024,
  desktop: 2560,
};

export const device = Object.entries(deviceSizeList).reduce((acc: any, size: any) => {
  const key = size[0];
  const value = size[1];
  acc[key] = `screen and (max-width: ${value}px)`;
  return acc;
}, {});

/**
 *
 * @device - ${({theme}) => theme.tablet} { css 영역 } (media query 대체)
 */
const theme: DefaultTheme = {
  color,
  ...device,
};
export default theme;
