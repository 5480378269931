import { createSlice } from '@reduxjs/toolkit';
import UserDefault from 'lib/UserDefault';

export const name = 'navMenu';

const initialState = {
  navMenu: [],
};

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    toggleTitle: (state, action) => {
      state.navMenu = state.navMenu.map(deps1 => {
        if (action.payload === deps1.title) {
          return { ...deps1, isOpen: !deps1.isOpen };
        } else {
          return { ...deps1 };
        }
      });
    },
    updateNavMenu: (state, action) => {
      const { userPermission, navList } = action.payload;
      if (userPermission === 1) {
        state.navMenu = navList.map(deps1 => {
          return {
            ...deps1,
            isOpen: false,
          };
        });
      } else {
        const result = navList
          .filter(item => {
            const pes = r => {
              return (
                r.permissionBit === undefined ||
                UserDefault.BigIntBitAnd(userPermission, r.permissionBit) != 0
              );
            };

            return item.children.some(v => {
              return pes(item) && pes(v);
            });
          })
          .map(deps1 => {
            const result1 = {
              ...deps1,
              isOpen: false,
              children:
                deps1.children &&
                deps1.children
                  .filter(
                    item =>
                      item.permissionBit === undefined ||
                      UserDefault.BigIntBitAnd(userPermission, item.permissionBit) != 0,
                  )
                  .map(deps2 => {
                    const result2 = {
                      ...deps2,
                      children:
                        deps2.children &&
                        deps2.children
                          .filter(
                            item =>
                              item.permissionBit === undefined ||
                              UserDefault.BigIntBitAnd(userPermission, item.permissionBit) != 0,
                          )
                          .map(deps3 => {
                            const result3 = {
                              ...deps3,
                            };
                            if (deps3.permissionBit || deps2.permissionBit) {
                              result3.permissionBit = deps3.permissionBit || deps2.permissionBit;
                            }
                            return result3;
                          }),
                    };
                    if (deps2.permissionBit || deps1.permissionBit) {
                      result2.permissionBit = deps2.permissionBit || deps1.permissionBit;
                    }
                    return result2;
                  }),
            };
            if (deps1.permissionBit) {
              result1.permissionBit = deps1.permissionBit;
            }

            return result1;
          });
        state.navMenu = result;
      }
    },
    clearNavMenu: state => {
      state.navMenu = [];
    },
  },
});

export const { toggleTitle, clearNavMenu, updateNavMenu } = slice.actions;

export default slice.reducer;
