import { createSlice } from '@reduxjs/toolkit';

export const name = 'rendering';

const initialState = {
  loading: false,
};

const rendering = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = rendering.actions;

export default rendering.reducer;
