import ApiAuth, {
  LoginType,
  SignUpRequstType,
} from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/admin/ApiAuth';
import UserDefault from 'lib/UserDefault';
import { updateLoginToken, clearLoginToken, updateUserInfo } from 'store/slices/user';
import { useDispatch } from 'react-redux';
import { NetworkCallback } from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/ApiManager';
import { useState } from 'react';
import { alertModal } from 'lib/Modals/alertModal';

const useAuth = () => {
  const dispatch = useDispatch();
  const [bitList, setBitList] = useState({
    languageList: [],
    permissionList: {},
  });
  const login = async (request: LoginType, callback: NetworkCallback) => {
    try {
      const response = await ApiAuth.getInstance(UserDefault.getApiDefaultHeaders()).login(request);
      const { sendMessage } = await import('lib/utils/debugConsole');
      sendMessage(response);
      const { accessToken, profile } = response.data.data;
      const { loginId, permissionBit, name, email, department } = profile;
      dispatch(updateLoginToken(accessToken));
      console.log(profile);
      dispatch(
        updateUserInfo({
          userId: loginId,
          name,
          department,
          email,
          userPermission: permissionBit,
        }),
      );
      callback.onComplete(response.data.data);
      // 입장시 유저 권한 부여
    } catch (err: any) {
      callback.onError(err);
      dispatch(clearLoginToken());
    }
  };

  const signUp = (request: SignUpRequstType, callback: NetworkCallback) => {
    try {
      ApiAuth.getInstance(UserDefault.getApiDefaultHeaders())
        .signUpRequest(request)
        .then((res: any) => {
          const { data } = res.data;
          callback.onComplete(data);
        })
        .catch((err: any) => {
          callback.onError(err);
        });
    } catch (err: any) {
      alert(err.response.data.error);
    }
  };

  const permissionList = () => {
    try {
      ApiAuth.getInstance(UserDefault.getApiDefaultHeaders())
        .getPermissionBitList()
        .then((res: any) => {
          const { data } = res.data;
          setBitList(data);
        })
        .catch((err: any) => {
          alertModal('ERROR', { msg: err.response.data.error });
        });
    } catch (err: any) {
      alert(err.response.data.error);
    }
  };

  return { login, signUp, bitList, permissionList };
};

export default useAuth;
