/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
export const name = 'paymethod';

const initialState = {
  methodList: [],
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    initialMethodGroup: (state, action) => {
      const { list, paymentTypeName } = action.payload;
      const result = Object.entries(list).map(([key, method]) => {
        return {
          name: paymentTypeName[key],
          _key: key,
          item: method,
        };
      });
      state.methodList = result;
    },
    setMethodGroup: (state, action) => {
      const { currentList, groupType } = action.payload;
      const { methodList } = state;

      const mIndex = methodList.findIndex(v => v.name === groupType);

      const copyMList = [...methodList];
      copyMList[mIndex].item = currentList;
      console.log(copyMList);

      state.methodList = copyMList;
    },
    setMethodItem: (state, action) => {
      const { currentList, lockIndex } = action.payload;
      const { methodList } = state;
      const tempList = [...methodList];
      const _blockIndex = [...blockIndex];
      const lastIndex = _blockIndex.pop();
      const lastArr = _blockIndex.reduce((arr, i) => arr[i]['list'], tempList);
      lastArr[lastIndex]['list'] = currentList;
      state.methodList = tempList;
    },
  },
  extraReducers: {},
});

export const { initialMethodGroup, setMethodGroup, setMethodItem } = slice.actions;

export default slice.reducer;
