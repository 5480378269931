import * as alasql from 'alasql';
import {
  ComicModel,
  EpisodeModel,
  SignModel,
  PromotionCodeModel,
  PromotionDayModel,
  AllUserCoinModel,
} from './model';
import moment from 'moment-timezone';

const createTables = (table, tableList) => {
  const items = tableList
    .reduce((acc, { name, type }) => {
      return acc.concat(`${name} ${type.toUpperCase()}, `);
    }, '')
    .replace(/,\s*$/, '');
  return `CREATE TABLE IF EXIST ${table} (${items})`;
};

const CREATE_COMIC =
  'CREATE TABLE IF NOT EXISTS comic (comic INT, comicName STRING, koTitle STRING, pc INT, mobile INT, episodeCount INT, etc INT, depth2 STRING, viewCount INT, weekly STRING, coin INT, pcCoin INT, mobileCoin INT, etcCoin INT)';
const CREATE_EPISODE =
  'CREATE TABLE IF NOT EXISTS episode (weekly STRING, comicName STRING, episodeTitle STRING, pc INT, mobile INT, etc INT, viewCount INT, coin INT, pcCoin INT, mobileCoin INT, etcCoin INT)';
const CREATE_SIGN = 'CREATE TABLE IF NOT EXISTS sign (date STRING, signUp INT, signUpPercent INT)';
const CREATE_PROMOTION_CODE =
  'CREATE TABLE IF NOT EXISTS promotionCode (code STRING, codeName STRING, total STRING, signUpCount INT, signUpRate INT, loginCount INT, paymentCount INT, paymentAmount INT, paymentRate INT, currencyCode STRING)';
const CREATE_PROMOTION_DAY =
  'CREATE TABLE IF NOT EXISTS promotionDay (date STRING, total STRING, signUpCount INT, signUpRate INT, loginCount INT, paymentCount INT, paymentAmount INT, paymentRate INT, currencyCode STRING)';
const CREATE_ALL_USER_COIN =
  'CREATE TABLE IF NOT EXISTS allUserCoin (typeName STRING, totalCoin INT, useCoin INT, expiredCoin INT, isExpireCoin BOOLEAN, delCoin INT, remainingCoin INT, usePercent INT)';

const CREATE_GOODS = createTables('goods', [
  { name: 'goodsId', type: 'int' },
  { name: 'name', type: 'string' },
  { name: 'quantity', type: 'int' },
]);

export const createDatabase = () => {
  alasql(`
      CREATE LOCALSTORAGE DATABASE IF NOT EXISTS topco;
      ATTACH LOCALSTORAGE DATABASE topco;
      USE topco;
    `);
};

export const createTable = () => {
  alasql(CREATE_COMIC);
  alasql(CREATE_EPISODE);
  alasql(CREATE_SIGN);
  alasql(CREATE_ALL_USER_COIN);
};

export const insert = (table, data) => {
  switch (table) {
    case 'sign': {
      insertSign(data);
      break;
    }
    case 'comic': {
      insertComic(data);
      break;
    }
    case 'episode': {
      insertEpisode(data);
      break;
    }
    case 'promotionCode': {
      insertPromotionCode(data);
      break;
    }
    case 'promotionDay': {
      insertPromotionDay(data);
      break;
    }
    case 'allUserCoin': {
      insertAllUserCoin(data);
      break;
    }
  }
};

const insertSign = data => {
  let item = SignModel({
    date: moment(data.date).format('YYYY-MM-DD'),
    signUp: Number(data.signUp),
    signUpPercent: data.signUpRate,
  });
  alasql(`insert into sign values ?`, [item]);
};

const insertComic = data => {
  let item = ComicModel({
    comic: Number(data.comicId),
    comicName: `${data.title} / ${data.koTitle}`,
    koTitle: data.koTitle,
    episodeCount: data.episodeCount,
    pc: Number(data.pcViewCount),
    mobile: Number(data.mobileViewCount),
    etc: Number(data.etcViewCount),
    depth2: data.depth2 === null ? '' : data.depth2,
    viewCount: data.viewCount,
    weekly: data.weeklyToString,
    coin: data.coin,
    pcCoin: Number(data.pcCoin),
    mobileCoin: Number(data.mobileCoin),
    etcCoin: Number(data.etcCoin),
  });
  alasql(`insert into comic values ?`, [item]);
};

const insertEpisode = data => {
  let item = EpisodeModel({
    weekly: data.weeklyToString,
    comicName: `${data.comicTitle} / ${data.koTitle}`,
    episodeTitle: data.episodeTitle,
    viewCount: Number(data.viewCount),
    pc: Number(data.pcViewCount),
    mobile: Number(data.mobileViewCount),
    etc: Number(data.etcViewCount),
    coin: data.coin,
    pcCoin: Number(data.pcCoin),
    mobileCoin: Number(data.mobileCoin),
    etcCoin: Number(data.etcCoin),
  });
  alasql(`insert into episode values ?`, [item]);
};

const insertPromotionCode = data => {
  let item = PromotionCodeModel({
    code: data.code,
    total: Number(data.total),
    codeName: data.partnerCodeDescription,
    signUpCount: Number(data.signUp),
    signUpRate: Number(data.signUpRate),
    loginCount: Number(data.signIn),
    paymentCount: Number(data.paymentCount),
    paymentAmount: Number(data.paymentAmount),
    paymentRate: Number(data.paymentRate),
    currencyCode: data.currencyCode ? data.currencyCode : '',
  });
  alasql(`insert into promotionCode values ?`, [item]);
};

const insertPromotionDay = data => {
  let item = PromotionDayModel({
    date: data.date.includes('00:00:00') ? moment(data.date).format('YYYY-MM-DD') : data.date,
    total: Number(data.total),
    signUpCount: Number(data.signUp),
    signUpRate: Number(data.signUpRate),
    loginCount: Number(data.signIn),
    paymentCount: Number(data.paymentCount),
    paymentAmount: Number(data.paymentAmount),
    paymentRate: Number(data.paymentRate),
    currencyCode: data.currencyCode ? data.currencyCode : '',
  });
  alasql(`insert into promotionDay values ?`, [item]);
};

const insertAllUserCoin = data => {
  let item = AllUserCoinModel({
    typeName: data.typeName,
    totalCoin: data.totalCoin,
    useCoin: data.useCoin,
    expiredCoin: data.expiredCoin,
    isExpireCoin: data.isExpireCoin,
    delCoin: data.delCoin,
    remainingCoin: data.remainingCoin,
    usePercent: data.usePercent,
  });
  alasql(`insert into allUserCoin values ?`, [item]);
};

export const getData = (table, filter = '') => {
  switch (table) {
    case 'sign': {
      return getDataSign();
    }
    case 'comic': {
      return getComicData(filter);
    }
    case 'episode': {
      return getEpisodeData(filter);
    }
    case 'promotionCode': {
      return getPromotionCodeData();
    }
    case 'promotionDay': {
      return getPromotionDayData();
    }
    case 'allUserCoin': {
      return getAllUserCoinData();
    }
  }
};

const getDataSign = () => {
  let list = alasql(`SELECT * FROM sign`);
  let result = alasql(`SELECT SUM(signUp) AS signUpCount FROM sign`);
  if (list.length === 0) {
    return {
      signUpCount: 0,
      list: [],
    };
  } else {
    return {
      ...result[0],
      list: list,
    };
  }
};

const getComicData = filter => {
  const where = filter === '전체' ? '' : `WHERE weekly LIKE '${filter}'`;
  let list = alasql(`SELECT * FROM comic ${where}`);
  let result = alasql(
    `SELECT SUM(viewCount) AS viewCount, SUM(pc) AS pc, SUM(mobile) AS mobile, SUM(etc) AS etc, SUM(coin) AS coin, SUM(pcCoin) AS pcCoin, SUM(mobileCoin) AS mobileCoin, SUM(etcCoin) AS etcCoin FROM comic ${where}`,
  );

  if (list.length === 0) {
    return {
      viewCount: 0,
      pc: 0,
      mobile: 0,
      etc: 0,
      episodeCount: 0,
      coin: 0,
      pcCoin: 0,
      etcCoin: 0,
      mobileCoin: 0,
      list: [],
    };
  } else {
    return {
      ...result[0],
      list: list,
    };
  }
};

const getEpisodeData = filter => {
  const where = filter === '전체' ? '' : `WHERE weekly LIKE '${filter}'`;
  let list = alasql(`SELECT * FROM episode ${where}`);
  let result = alasql(
    `SELECT SUM(viewCount) AS viewCount, SUM(pc) AS pc, SUM(mobile) AS mobile, SUM(etc) AS etc, SUM(coin) AS coin, SUM(pcCoin) AS pcCoin, SUM(mobileCoin) AS mobileCoin, SUM(etcCoin) AS etcCoin FROM episode ${where}`,
  );
  if (list.length === 0) {
    return {
      viewCount: 0,
      pc: 0,
      mobile: 0,
      etc: 0,
      coin: 0,
      pcCoin: 0,
      mobileCoin: 0,
      etcCoin: 0,
      list: [],
    };
  } else {
    return {
      ...result[0],
      list: list,
    };
  }
};

const getPromotionCodeData = () => {
  return alasql(`SELECT * FROM promotionCode`);
};

const getPromotionDayData = () => {
  return alasql(`SELECT * FROM promotionDay`);
};

const getAllUserCoinData = () => {
  return alasql(`SELECT * FROM allUserCoin`);
};

export const truncateTable = table => {
  alasql(`TRUNCATE TABLE ${table}`);
};

export const dropTable = table => {
  alasql(`DROP TABLE IF EXISTS ${table}`);
  let query = '';
  switch (table) {
    case 'comic': {
      query = CREATE_COMIC;
      break;
    }
    case 'episode': {
      query = CREATE_EPISODE;
      break;
    }
    case 'sign': {
      query = CREATE_SIGN;
      break;
    }
    case 'promotionCode': {
      query = CREATE_PROMOTION_CODE;
      break;
    }
    case 'promotionDay': {
      query = CREATE_PROMOTION_DAY;
      break;
    }
    case 'allUserCoin': {
      query = CREATE_ALL_USER_COIN;
      break;
    }
    default: {
      query = '';
      break;
    }
  }
  if (query === '') return;

  alasql(query);
};
