import { ModalInstance } from 'lib/ModalContainer';

type ConfirmProps = {
  id?: number;
  title?: string;
  msg?: string;
  component?: any;
};

type ModalProps = {
  title?: string;
  msg?: string;
  onClick?: () => void;
  onClose?: () => void; // 닫힌후 이벤트
};

const confirmModal = (
  type: 'SAVE' | 'DELETE',
  api: () => void,
  { title = '', msg = '', id = 0, component }: ConfirmProps = {},
) => {
  const idDescription = id ? `id: ${id} ` : '';
  if (type === 'SAVE') {
    ModalInstance.getInstance().push({
      key: 'saveModal',
      buttonType: 'confirm',
      onClick: () => api(),
      info: {
        title: title || '저장 확인',
        description: msg || `${idDescription} 저장하시겠습니까?`,
      },
      component: typeof component === 'function' && component(),
    });
  } else if (type === 'DELETE') {
    ModalInstance.getInstance().push({
      key: 'deleteModal',
      buttonType: 'delete',
      onClick: () => api(),
      info: {
        title: title || '삭제 확인',
        description: msg || `${idDescription} 삭제하시겠습니까?`,
      },
    });
  }
};

const alertModal = (
  type: 'ALERT' | 'ERROR' | 'SAVE_AFTER' | 'DELETE_AFTER' = 'ALERT',
  { title = '', msg = '', onClick = () => {}, onClose = () => {} }: ModalProps = {},
) => {
  if (type === 'ALERT') {
    ModalInstance.getInstance().push({
      key: 'normalAlert',
      buttonType: 'alert',
      onClick: () => onClick(),
      onClose: () => onClose(),
      info: {
        title: title || '확인',
        description: msg || ' ',
      },
    });
  } else if (type === 'ERROR') {
    ModalInstance.getInstance().push({
      key: 'errorAlert',
      buttonType: 'error',
      onClick: () => onClick(),
      onClose: () => onClose(),
      info: {
        title: title || 'ERROR!',
        description: msg || '오류가 발생했습니다.',
      },
    });
  } else if (type === 'SAVE_AFTER') {
    ModalInstance.getInstance().push({
      key: 'saveAfterModal',
      buttonType: 'alert',
      onClick: () => onClick(),
      onClose: () => onClose(),
      info: {
        title: title || '저장 완료',
        description: msg || '저장이 완료되었습니다.',
      },
    });
  } else if (type === 'DELETE_AFTER') {
    ModalInstance.getInstance().push({
      key: 'deleteAfterModal',
      buttonType: 'alert',
      onClick: () => onClick(),
      onClose: () => onClose(),
      info: {
        title: title || '삭제 완료',
        description: msg || '삭제가 완료되었습니다.',
      },
    });
  }
};

export { confirmModal, alertModal };
