import { createSlice } from '@reduxjs/toolkit';
import { HostName } from 'components/statisticsv/_constants';
export const name = 'hostnameSlice';

const initialState = {
  list: [{ hostname: HostName.GLOBAL }, { hostname: HostName.JP }],
  hostname: HostName.GLOBAL,
};

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    changeHostname: (state, action) => {
      state.hostname = action.payload;
    },
  },
  extraReducers: {},
});

export const { changeHostname } = slice.actions;

export default slice.reducer;
