import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import './scss/style.scss';
import { ToastProvider } from 'react-toast-notifications';
import { createDatabase } from 'lib/db/database';
import { useSelector, useDispatch } from 'react-redux';
import ModalContainer from 'lib/ModalContainer';
import { ThemeProvider } from 'styled-components';
import theme from 'scss/theme';
import { clearCommonList } from 'store/slices/common';
import Signup from 'views/pages/signup/Signup';
import UserDefault from 'lib/UserDefault';
import ResetPassword from 'views/pages/resetPassword/ResetPassword';
import ApiAuth from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/admin/ApiAuth';
import Cookies from 'js-cookie';
import { clearNavMenu, updateNavMenu } from 'store/slices/navMenu';
import navigationV2 from './containers/_navV2';
import { clearLoginToken } from 'store/slices/user';
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const CustomLoading = React.lazy(() => import('./components/CustomLoading'));

const Permission = React.lazy(() => import('./views/pages/permissionPage/Permission'));

function App() {
  const loading = useSelector(state => state.rendering.loading);
  const loginToken = useSelector(state => state.user.loginToken);
  const userInfo = useSelector(state => state.user.userInfo);
  const history = useHistory();
  // 로그인 정보, 유저 정보 가져오기
  const dispatch = useDispatch();

  useEffect(() => {
    createDatabase(); // 데이터 베이스 생성
  }, []);

  useEffect(() => {
    // 토큰은 있는데 유저 정보가 없으면 로그아웃
    if (loginToken && !userInfo) {
      dispatch(clearLoginToken());
    }
    // 토큰, 유저정보 있는데 권한이 없으면 로그아웃
    if (loginToken && userInfo?.userId && !userInfo?.userPermission) {
      dispatch(clearLoginToken());
    }
    // 토근이 없으면
    if (!loginToken) {
      dispatch(clearNavMenu()); // 카테고리 초기화
      dispatch(clearCommonList()); // 언어정보, 호스트 네임 초기화
      localStorage.removeItem('bitList');
      // 권한이 있고, 토큰이 있을때
    } else if (userInfo?.userPermission > 0 && loginToken) {
      dispatch(
        // 권한에 맞게 메뉴 보여주기
        updateNavMenu({
          userPermission: userInfo?.userPermission,
          navList: navigationV2(),
        }),
      );
    }
  }, [loginToken, userInfo]);

  useEffect(() => {
    // 퍼미션, 호스트, 언어 로컬에 저장하는 로직
    // 저장후에 쿠키에 1시간 저장. 페이지 이동할때마다 확인, 버전 확인시 지워짐.v
    if (!Cookies.get('isCommonUpdate') || !localStorage.getItem('bitList')) {
      ApiAuth.getInstance(UserDefault.getApiDefaultHeaders())
        .getPermissionBitList()
        .then(res => {
          const { data } = res.data;
          const permissionnBitList = _.flattenDeep(
            Object.values(data.permissionList).map(v => v.list),
          ).map(item => {
            return {
              originalLabel: item.name,
              label: item.koreaName,
              bit: item.permissionBit,
            };
          });

          const languageBitList = data.languageList
            .filter(v => ['en', 'ko'].includes(v.language))
            .map(item => {
              return {
                label: item.language,
                bit: item.bit,
              };
            });

          const hostnameList = data.hostnameList.map(item => {
            return {
              hostname: item.hostname,
              label: item.label,
            };
          });

          UserDefault.bitList.setter({
            permission: permissionnBitList,
            language: languageBitList,
            hostname: hostnameList,
          });

          const time = new Date();
          time.setTime(time.getTime() + 1 * 60 * 60 * 1000); // 1시간
          Cookies.set('isCommonUpdate', true, { expires: time });
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [history]);

  return (
    <ToastProvider appearance="info" autoDismiss autoDismissTimeout={2000}>
      <ThemeProvider theme={theme}>
        <Router>
          <React.Suspense fallback={loading}>
            {loading && <CustomLoading />}
            <Switch>
              <Route
                exact
                path="/resetpass"
                name="비밀번호 찾기"
                render={props => <ResetPassword {...props} />}
              />
              <Route exact path="/login" name="로그인" render={props => <Login {...props} />} />
              <Route
                exact
                path="/signup"
                name="회원가입 및 권한신청"
                render={props => <Signup {...props} />}
              />
              <Route exact path="/404" name="404 에러" render={props => <Page404 {...props} />} />
              <Route
                exact
                path="/permission"
                name="권한 에러"
                render={props => <Permission {...props} />}
              />
              <Route exact path="/500" name="500 에러" render={props => <Page500 {...props} />} />
              <Route path="/" name="홈" render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
          <ModalContainer />
        </Router>
      </ThemeProvider>
    </ToastProvider>
  );
}

export default App;
