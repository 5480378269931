import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FormikProps, useFormik } from 'formik';
import useAuth from '../hooks/useAuth';
import { alertModal, confirmModal } from 'lib/Modals/alertModal';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BiArrowToLeft } from 'react-icons/bi';
import InputField, { DepartmentField } from 'components/FormiknputField';
import { useSelector } from 'react-redux';
import PermissionCheckTab from '../../master/PermissionCheckTab';
import { usePermissionCheck } from '../../master/PermissionCheckTab';
type Props = {};

interface SignUpValues {
  department: string;
  name: string;
  id: string;
  email: string;
  password: string;
  re_password: string;
}

const FormDraft = ({ title, children, ...rest }: any) => {
  return (
    <FormWrapStyle>
      <FormDraftStyle {...rest}>
        <h2>{title}</h2>
        {children}
      </FormDraftStyle>
    </FormWrapStyle>
  );
};

const Signup = (props: any) => {
  const { loginToken } = useSelector((state: any) => state.user);
  const { signUp, permissionList } = useAuth();
  const { selectLanguage, selectPermission, setSelectPermission, setSelectLanguage } =
    usePermissionCheck();

  const history = useHistory();

  useEffect(() => {
    if (loginToken) {
      history.push('/');
    }
  }, [loginToken]);

  useEffect(() => {
    // permissionList();
  }, []);

  const formik: FormikProps<SignUpValues> = useFormik<SignUpValues>({
    initialValues: {
      department: '',
      name: '',
      id: '',
      email: '',
      password: '',
      re_password: '',
    },
    validationSchema: Yup.object({
      department: Yup.string().required('부서명을 입력해주세요.'),
      name: Yup.string().required('이름을 입력해주세요.'),
      id: Yup.string().required('아이디를 입력해주세요.'),
      email: Yup.string().email('이메일 형식이 맞지 않습니다.').required('이메일을 입력해주세요.'),
      password: Yup.string()
        .min(6, '비밀번호는  6~15자 이내로 입력해주세요.')
        .max(15, '비밀번호는  6~15자 이내로 입력해주세요.')
        .required('비밀번호를 입력해주세요.'),
      re_password: Yup.string()
        .oneOf([Yup.ref('password')], '입력하신 비밀번호가 서로 다릅니다.')
        .required('비밀번호를 입력해주세요.'),
    }),
    onSubmit: values => {
      if (selectPermission.length === 0) {
        return alertModal('ERROR', {
          msg: '한개 이상의 권한을 선택해주세요.',
        });
      } else if (selectLanguage.length == 0) {
        return alertModal('ERROR', {
          msg: '한개 이상의 언어를 선택해주세요.',
        });
      }
      confirmModal(
        'SAVE',
        () =>
          signUp(
            {
              id: values.id,
              password: values.password,
              name: values.name,
              email: values.email,
              department: values.department,
              permissionNameList: selectPermission,
              languageNameList: selectLanguage,
            },
            {
              onComplete: () => {
                alertModal('SAVE_AFTER', {
                  title: '신청완료',
                  msg: '계정 신청이 완료되었습니다. 최고 관리자에게 문의해주세요.',
                  onClick: () => {
                    history.push('/login');
                  },
                  onClose: () => {
                    history.push('/login');
                  },
                });
              },
              onError: err => {
                const errMsg = err.response.data.error;
                if (errMsg.includes('exists')) {
                  return alertModal('ERROR', {
                    msg: '중복된 계정이 존재합니다.',
                  });
                }
                return alertModal('ERROR', { msg: errMsg });
              },
            },
          ),
        {
          title: '계정 신청 확인',
          component: () => {
            return (
              <AccountRequestStyle>
                <span className="darkgray">{values.department}</span>
                <span className="darkgray">{values.name}</span>
                <span>님의 계정</span>
                <span className="darkgray">{values.id}</span>
                <span>를 신청합니다.</span>
              </AccountRequestStyle>
            );
          },
        },
      );
    },
  });

  return (
    <SignUpField>
      <div className="header">
        <a href="/">
          <img alt="logo" src="/logos/logo.png" className="logo" />
        </a>
        <h2>탑툰 관리자 계정 신청</h2>
        <BiArrowToLeft
          onClick={() => {
            history.push('/login');
          }}
          className="back-btn"
        />
      </div>
      <PermissionCheckTab
        selectPermission={selectPermission}
        selectLanguage={selectLanguage}
        setSelectPermission={setSelectPermission}
        setSelectLanguage={setSelectLanguage}
        isPermissionChange={false}
      />
      <FormDraft title="UserInfo">
        <form onSubmit={formik.handleSubmit}>
          {/* TODO: 계정 정보 기입부분 */}
          <DepartmentField formik={formik} label="부서명" name="department" />
          <InputField formik={formik} label="이름" name="name" placeholder="이름을 입력해주세요." />
          <InputField
            formik={formik}
            label="이메일"
            name="email"
            placeholder="이메일을 입력해주세요. (비활성 계정 복구시 필요) "
          />
          <InputField
            formik={formik}
            name="id"
            label="아이디"
            placeholder="아이디를 입력해주세요."
          />
          <InputField
            formik={formik}
            label="비밀번호"
            name="password"
            type="password"
            placeholder="비밀번호를 입력해주세요."
          />
          <InputField
            formik={formik}
            label="비밀번호 확인"
            name="re_password"
            type="password"
            placeholder="비밀번호를 다시 입력해주세요."
          />
        </form>
        <p className="tail">
          전자결재 - 글로벌 서비스 관리자 권한 신청/변경 요청서도 같이 작성해주세요.{' '}
        </p>
      </FormDraft>
      <div className="btn_area">
        <button onClick={() => formik.handleSubmit()} className="submit-btn">
          가입 신청
        </button>
      </div>
    </SignUpField>
  );
};

export default Signup;

const FormWrapStyle = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 0 auto;

  @media (max-width: 576px) {
    max-width: 540px;
  }

  @media (max-width: 768px) {
    max-width: 720px;
  }

  @media (max-width: 1400px) {
    max-width: 980px;
  }
`;

const FormDraftStyle = styled.div`
  position: relative;
  border-radius: 0.25rem;
  background: white;
  padding: 30px;
  /* width: 50%; */
  margin: 20px 10px;
  box-shadow: 0 1px 1px 0 rgba(66, 71, 80, 0.14), 0 2px 1px -1px rgba(66, 71, 80, 0.12),
    0 1px 3px 0 rgba(66, 71, 80, 0.2);
  h2 {
    position: absolute;
    top: -17px;
    left: -8px;
    font-size: 1.6rem;
    text-shadow: 4px 3px 0 rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 767px) {
    padding: 30px 15px;
  }
`;

const SignUpField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0;

  .header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 60px;
    word-break: keep-all;
    background: #fff;
    /* margin-bottom: 30px; */
    box-shadow: 0 1px 1px 0 rgba(66, 71, 80, 0.14), 0 2px 1px -1px rgba(66, 71, 80, 0.12),
      0 1px 3px 0 rgba(66, 71, 80, 0.2);

    .logo {
      @media (max-width: 620px) {
        display: none;
      }
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
      height: 36px;
    }
    > h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
    }
    .back-btn {
      position: absolute;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      height: 36px;
      width: 30px;
    }
  }
  .btn_area {
    width: 100%;
    max-width: 980px;
    padding: 10px;
    > button {
      float: right;
      border: 1px solid #333;
      padding: 5px 10px;
      font-size: 1rem;
      font-weight: 500;
      background: white;
      border-radius: 3px;
      &:hover {
        outline: none;
        background: #ccc;
      }
    }
  }

  .permission_list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    > div {
      display: flex;
      align-items: center;
      margin: 10px 0;
      .name {
        font-size: 1.1rem;
        font-weight: 600;
        min-width: 100px;
        margin: auto 0;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &:hover {
          color: blue;
        }
        input[type='checkbox'] {
          display: none;
        }
      }
      .permission_item {
        background: #dcdbda;
        color: #515151;
        margin: 0 5px;
        padding: 5px 10px;
        font-weight: 500;
        font-size: 0.8rem;
        border-radius: 14px;
        -ms-user-select: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        &.select {
          background: #5757f1;
          color: #ffffff;
        }
        &.disable {
          background: #aeaeae;
          color: #d6d5d5;
        }
        input[type='checkbox'] {
          display: none;
        }
      }
    }
  }
  .language_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      display: flex;
      justify-content: center;
      label {
        box-sizing: border-box;
        border: 1px solid #dcdbda;
        border-radius: 3px;
        margin: 0 10px;
        display: block;
        padding: 0.5rem 1rem;
        cursor: pointer;
        &.select {
          border: 1px solid #5757f1;
          background: #5757f1;
        }
        input[type='checkbox'] {
          display: none;
        }
      }
    }
  }
  .tail {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    font-size: 0.75rem;
    color: #555;
  }
  form {
  }
`;

const AccountRequestStyle = styled.div`
  span {
    margin-right: 5px;
  }

  .darkgray {
    color: #575757;
    font-weight: 600;
  }
`;
