import { CFormGroup, CCol, CLabel, CInputCheckbox } from '@coreui/react';

interface CheckProps {
  index?: number;
  item: any;
}

export const Check = ({ index, item }: CheckProps) => {
  return (
    <CFormGroup variant="custom-checkbox" inline>
      <CInputCheckbox
        custom
        id={`check-${item.key || item.name}`}
        name="checkbox-nonadult"
        checked={item.value}
        onChange={(e: any) => {
          item.setValue(e.target.checked);
        }}
      />
      <CLabel variant="custom-checkbox" htmlFor={`check-${item.key || item.name}`}>
        {item.name}
      </CLabel>
    </CFormGroup>
  );
};

/**
 * check box View
 * @checkItems : object array
 * [{
 *    name: string,
 *    value: number (1:check)
 *    setValue: 원본상태 갱신
 * }]
 * @constructor created by jadejun 2021.05.14
 */

interface CheckBoxProps {
  title: string;
  checkItems: any[];
}

const CheckBox = ({ title, checkItems }: CheckBoxProps) => {
  return (
    <CFormGroup row>
      <CCol md="3">
        <CLabel>{title}</CLabel>
      </CCol>
      <CCol xs="12" md="9">
        {checkItems.map((c, index) => {
          return <Check index={index} key={index} item={c} />;
        })}
      </CCol>
    </CFormGroup>
  );
};

export default CheckBox;
