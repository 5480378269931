import { createSlice } from '@reduxjs/toolkit';

export const name = 'ImgSlice';

const initialState = {
  buttonCoords: {
    x: -120,
    y: 50,
  },
};

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    saveCoord: (state, action) => {
      state.buttonCoords = action.payload;
    },
  },
});

export const { saveCoord } = slice.actions;

export default slice.reducer;
