import { combineReducers } from '@reduxjs/toolkit';

import UserSlice from './user';
import ToastSlice from './toastSlice';
import viewSlice from './view';
import ImgSlice from './imgQueue';
import buttonCoord from './buttonCoord';

import common from './common';
import navMenu from './navMenu';

// support
import Rendering from './support/rendering';

import Paymethod from './paymethod';
import hostname from './hostname';

import Comment from './comment';
import comicSync from './comicSync';

export default combineReducers({
  user: UserSlice,
  toast: ToastSlice,
  view: viewSlice,
  img: ImgSlice,
  nav: navMenu,
  button: buttonCoord,
  rendering: Rendering,
  common: common,
  hostname: hostname,
  paymethod: Paymethod,
  comment: Comment,
  comicSync: comicSync,
});
