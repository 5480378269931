import { createSlice } from '@reduxjs/toolkit';

export const name = 'UserSlice';

const initialState = {
  loginToken: null,
  userInfo: {
    name: '',
    department: '',
    userId: null,
    email: '',
    userPermission: 0,
  },
};

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    clearLoginToken: state => {
      state.loginToken = null;
      state.userInfo = initialState.userInfo;
    },
    updateLoginToken: (state, action) => {
      state.loginToken = action.payload;
    },
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { clearLoginToken, updateLoginToken, updateUserInfo } = slice.actions;

export default slice.reducer;
