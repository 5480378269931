export const timezoneList = ['KST', 'EST', 'UTC'];

export const timezoneListLocal = ['KST', 'EST', 'UTC', 'LOCAL'];

export const daysList = ['전체', '월', '화', '수', '목', '금', '토', '일'];
export const daysListV2 = [
  { label: '전체', value: '' },
  { label: '월', value: 'mon' },
  { label: '화', value: 'tue' },
  { label: '수', value: 'wed' },
  { label: '목', value: 'thu' },
  { label: '금', value: 'fri' },
  { label: '토', value: 'sat' },
  { label: '일', value: 'sun' },
];

export const weeklyList = ['전체', '평일', '주말'];

export const matureList = ['전체', '성인', '비성인'];

export const completeList = ['전체', '완결', '연재중'];

export const deviceList = ['선택안함', 'PC', 'MOBILE', 'ALL'];

export const artworkSortList = ['최신순', '인기순'];

export const startTimeList = Array(24)
  .fill()
  .map((obj, index) => `${index}시`);

export const endTimeList = Array(24)
  .fill()
  .map((obj, index) => `${index + 1}시`);

export const hourTimeList = ['전체', ...endTimeList];
export const averageList = Array(30)
  .fill()
  .map((obj, index) => {
    if (obj === 0) return;
    return `${index}주`;
  });

export const paymentLogSearchKeyList = [
  '전체',
  '유저 ID',
  '유저 Email',
  '주문번호',
  'token',
  'tId',
  'payCode',
];

/**
 * 회원이 접속한 도메인 기준으로 구분
 */
export const HostName = {
  GLOBAL: 'toptoonplus.com',
  JP: 'toptoon.jp',
};

export const PartnerHostName = {
  GLOBAL: 'toptoonplus.com',
  OMAMAN: 'omeman.com', // 글로벌 광고용
  JP: 'toptoon.jp',
  WAQUWAQU: 'waquwaqu.jp', // 일본 광고용
};

export const HostNameList = [
  { hostname: 'toptoonplus.com', domain: '글로벌' },
  { hostname: 'toptoon.jp', domain: '일본' },
];

export const DepartmentList = [
  {
    department: 'HR기획본부',
    list: ['인사팀'],
  },
  {
    department: '경영지원본부',
    list: ['총무팀', '법무팀', '재무회계팀'],
  },
  {
    department: '콘텐츠본부',
    list: ['정산팀', '편집1부', '편집2부'],
  },
  {
    department: '제작스튜디오본부',
    list: ['콘텐츠지원부', '콘텐츠제작부'],
  },
  {
    department: '오메가스튜디오본부',
    list: ['오메가PD팀', '오메가스토리팀', '오메가선화팀', '오메가채색팀'],
  },
  {
    department: '플랫폼사업본부',
    list: ['플랫폼사업부', '플랫폼기획부', '플랫폼지원부'],
  },
  {
    department: '일본사업부',
    list: ['서비스운영팀', '마케팅팀', '해외유통팀'],
  },

  {
    department: '개발본부',
    list: ['국내서비스개발부', '해외서비스개발부', '제휴서비스개발부', '인프라부'],
  },
  { department: '기타', list: [] },
];

export const LanguageList = [
  { label: 'EN', value: 'en' },
  { label: 'KO', value: 'ko' },
  { label: 'ES', value: 'es' },
  { label: 'JA', value: 'ja' },
];
