import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAuth from 'views/pages/hooks/useAuth';
import CIcon from '@coreui/icons-react';
import UserDefault from '../../lib/UserDefault';
import parser from 'lib/parserUtils';
import _ from 'lodash';
type Props = {};

export const usePermissionCheck = () => {
  const DEFAULT_PERMISSION = 256;
  const [selectPermission, setSelectPermission] = useState<string[]>(['sync']);
  const [selectLanguage, setSelectLanguage] = useState<string[]>(['ko']);
  return {
    DEFAULT_PERMISSION,
    selectPermission,
    setSelectPermission,
    selectLanguage,
    setSelectLanguage,
  };
};

const PermissionCheckTab = ({
  selectPermission,
  selectLanguage,
  setSelectPermission,
  setSelectLanguage,
  isPermissionChange, // signup : false, permissionChangeModal : true
}: any) => {
  const { signUp, bitList, permissionList } = useAuth();

  useEffect(() => {
    permissionList();
  }, []);

  // useEffect(() => {
  //   if (
  //     Object.values(bitList.permissionList).reduce(
  //       (acc, cur: any) => acc + cur.permissionBit,
  //       -1,
  //     ) === sumPermissionBit
  //   ) {
  //     setSumPermissionBit(1);
  //   }
  // }, [sumPermissionBit]);
  const FormDraft = ({ title, children, ...rest }: any) => {
    return (
      <FormWrapStyle>
        <FormDraftStyle {...rest}>
          <h2>{title}</h2>
          {children}
        </FormDraftStyle>
      </FormWrapStyle>
    );
  };
  return (
    <StyleWrap>
      <FormDraft title="Permission" className="permission_list">
        {/* TODO: permissionBit 신청 부분 */}
        {Object.entries(bitList.permissionList).map(([groupKey, subList]: any) => {
          const isCheck = selectPermission.includes('generalManager');
          const isSuperAdmin = selectPermission.includes('generalManager');
          return (
            <div key={groupKey}>
              <h3 className="name">
                <label
                  className={`name${isCheck ? 'select' : isSuperAdmin ? 'disable' : ''}`}
                  key={subList.koreanName}
                >
                  <input
                    type="checkbox"
                    checked={isCheck}
                    disabled={subList.permissionBit !== 1 && isSuperAdmin}
                    onChange={() => {
                      const isChildCheck = subList.list.some((v: any) => {
                        return selectPermission.includes(v.name);
                      }); //자식이 한개라도 체크되어있는지
                      if (subList.permissionBit === 1) {
                        return setSelectPermission([!isChildCheck ? 'generalManager' : '']);
                      }

                      if (!isChildCheck) {
                        // 자식이 한개도 체크가 안되있는경우
                        return setSelectPermission((prev: any) => [
                          ...prev,
                          ...subList.list.map((v: any) => v.name),
                        ]);
                      } else {
                        // 자식이 한개 이상 선택되어있는경우
                        const isEveryCheck = subList.list.every((v: any) => {
                          return selectPermission.includes(v.name);
                        });
                        if (isEveryCheck) {
                          setSelectPermission((prev: any) => [
                            ...prev.filter(
                              (v: any) => !subList.list.map((v: any) => v.name).includes(v),
                            ),
                          ]);
                        } else {
                          setSelectPermission((prev: any) => {
                            return _.uniq([...prev, ...subList.list.map((v: any) => v.name)]);
                          });
                        }
                      }
                    }}
                  />
                  {subList.koreanName}
                </label>
              </h3>
              {subList.list.map((item: any) => {
                const isCheck = selectPermission.includes(item.name);

                // 앱 버전 권한은 콘솔로 설정할 수 없도록 하드코딩
                if (!isPermissionChange && item.koreaName === '앱 버전') return <></>;

                return (
                  <label
                    className={`permission_item ${
                      isCheck ? 'select' : isSuperAdmin ? 'disable' : ''
                    }`}
                    key={item.name}
                  >
                    <input
                      type="checkbox"
                      checked={isCheck}
                      disabled={item.permissionBit !== 1 && isSuperAdmin}
                      onChange={() => {
                        if (isCheck) {
                          if (item.permissionBit === 1) {
                            return setSelectPermission([]);
                          }
                          return setSelectPermission((prev: any) => [
                            ...prev.filter((v: any) => v !== item.name),
                          ]);
                        } else {
                          if (item.permissionBit === 1) {
                            return setSelectPermission(['generalManager']);
                          }
                          return setSelectPermission((prev: any) => [...prev, item.name]);
                        }
                      }}
                    />
                    {item.koreaName}
                  </label>
                );
              })}
            </div>
          );
        })}
        <p className="tail">데이터 동기화 권한은 필수로 체크해주세요.</p>
      </FormDraft>
      <FormDraft title="Language" className="language_list">
        {/* TODO: LanguageBit 신청 부분 */}
        <div>
          {bitList.languageList
            .filter((v: any) => v.language !== 'ko')
            .map((item: any) => {
              const isCheck = selectLanguage.includes(item.language);
              const icon = _.find(UserDefault.languageIcons, [
                'language',
                parser.String(item, 'language'),
              ])?.icon;
              return (
                <label className={`language_item ${isCheck ? 'select' : ''}`} key={item.language}>
                  <input
                    type="checkbox"
                    checked={isCheck}
                    onChange={() => {
                      if (isCheck) {
                        setSelectLanguage((prev: any) => [
                          ...prev.filter((v: any) => v !== item.language),
                        ]);
                      } else {
                        setSelectLanguage((prev: any) => [...prev, item.language]);
                      }
                    }}
                  />
                  {icon ? <CIcon name={icon} /> : item.language}
                </label>
              );
            })}
        </div>
        <p className="tail">한국은 기본 선택입니다.</p>
      </FormDraft>
    </StyleWrap>
  );
};

export default PermissionCheckTab;

const FormWrapStyle = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 0 auto;

  @media (max-width: 576px) {
    max-width: 540px;
  }

  @media (max-width: 768px) {
    max-width: 720px;
  }

  @media (max-width: 1400px) {
    max-width: 980px;
  }
`;
const FormDraftStyle = styled.div`
  position: relative;
  border-radius: 0.25rem;
  background: white;
  padding: 30px;
  /* width: 50%; */
  margin: 20px 10px;
  box-shadow: 0 1px 1px 0 rgba(66, 71, 80, 0.14), 0 2px 1px -1px rgba(66, 71, 80, 0.12),
    0 1px 3px 0 rgba(66, 71, 80, 0.2);
  h2 {
    position: absolute;
    top: -17px;
    left: -8px;
    font-size: 1.6rem;
    text-shadow: 4px 3px 0 rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 767px) {
    padding: 30px 15px;
  }
`;

const StyleWrap = styled.div`
  width: 100%;
  .permission_list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 10px 0;
      .name {
        font-size: 1.1rem;
        font-weight: 600;
        min-width: 85px;
        margin: auto 0;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &:hover {
          color: blue;
        }
        input[type='checkbox'] {
          display: none;
        }
      }
      .permission_item {
        background: #dcdbda;
        color: #515151;
        margin: 3px 5px;
        padding: 5px 10px;
        font-weight: 500;
        font-size: 0.8rem;
        white-space: nowrap;
        border-radius: 14px;
        -ms-user-select: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        &.select {
          background: #5757f1;
          color: #ffffff;
        }
        &.disable {
          background: #aeaeae;
          color: #d6d5d5;
        }
        input[type='checkbox'] {
          display: none;
        }
      }
    }
  }
  .language_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      display: flex;
      justify-content: center;
      label {
        box-sizing: border-box;
        border: 1px solid #dcdbda;
        border-radius: 3px;
        margin: 0 10px;
        display: block;
        padding: 0.5rem 1rem;
        cursor: pointer;
        &.select {
          border: 1px solid #5757f1;
          background: #5757f1;
        }
        input[type='checkbox'] {
          display: none;
        }
      }
    }
  }
`;
