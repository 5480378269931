import { createSlice } from '@reduxjs/toolkit';

export const name = 'comicSync';

const initialState = {
  time: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateTime: (state, action) => {
      if (!action.payload) return state;

      const storedTime = new Date(state.time).valueOf();
      const newTime = new Date(action.payload).valueOf();
      const currentTime = new Date().valueOf();

      if (storedTime > newTime) return state;
      if (currentTime > newTime) return state;
      state.time = action.payload;
    },
    checkTime: state => {
      if (!state.time) return state;

      const storedTime = new Date(state.time).valueOf();
      const currentTime = new Date().valueOf();

      if (storedTime <= currentTime) {
        state.time = null;
      }
    },
  },
});

export const { updateTime, checkTime } = slice.actions;

export default slice.reducer;
