import {
  CButton,
  CButtonToolbar,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CRow,
} from '@coreui/react';
import React, { useState } from 'react';

import CIcon from '@coreui/icons-react';
import FileUpload from 'components/FileUpload';
import ImageResizeNotice from 'components/ImageResizeNotice';
import ImageUploadV2 from 'components/ImageUploadV2';
import SortItemBox from 'components/SortItemBox';

/**
 * 관리자 파일 업로드 Form
 * @param title : 제목
 * @param token : 인증 token
 * @param requestForm : file upload시 파일 정보 (width 등등)
 * @param imageContent : image 정보 리스트
 * @param setImagContent : image 정보리스트 set
 * @param removeUrl : 이미지 삭제시 요청할 url
 * @param removeRequest : 이미지 삭제시 전문 정보
 * @param uploadUrl : 파일 업로드 url
 * @param tag : tag
 * @param onUploadComplete : file upload 모두 완료시 callback
 * @param showViewer : 이미지 viewer로 보기 callback function
 * @param onSortSave : 정렬 저장 버튼 callback function
 * @returns {JSX.Element}
 * @constructor created by jadejun 2021.05.14
 */
const EpisodeImageUploadForm = ({
  title,
  token,
  requestForm,
  imageContent,
  setImagContent,
  removeUrl,
  removeRequest,
  uploadUrl,
  tag,
  onUploadComplete,
  onSortSave,
  showViewer,
  onDeleteAll,
}) => {
  const [uploadFormType, setUploadFormType] = useState(0);
  // type = 0 일반 이미지 업로드폼
  // type = 1 epub 업로드 폼

  let fileUploadRequestForm = Object.assign({}, requestForm);
  if (fileUploadRequestForm.width) {
    delete fileUploadRequestForm.width;
  }
  return (
    <CFormGroup>
      <CCardHeader htmlFor="name">
        {title}
        <ImageResizeNotice {...requestForm} />
        <CRow>
          <CCol xs="12" className="my-2">
            <CButtonToolbar justify="between">
              <CButtonToolbar justify="start">
                {showViewer !== undefined && (
                  <CButton
                    size="sm"
                    color="dark"
                    onClick={e => {
                      if (imageContent.length === 0) {
                        return alert('이미지가 없습니다.');
                      }
                      showViewer(e, title, imageContent);
                    }}
                  >
                    <CIcon name="cil-library" />
                    <span className="mx-1" />
                    뷰어로 보기
                  </CButton>
                )}

                {tag === 'episodeContents' && (
                  <CButton className="ml-4" size="sm" color="danger" onClick={e => onDeleteAll(e)}>
                    <CIcon name="cil-trash" />
                    <span className="mx-1" />
                    이미지 전체 삭제
                  </CButton>
                )}
                {tag === 'episodeContents' && (
                  <CButton
                    className="ml-4"
                    size="sm"
                    color="light"
                    onClick={() => {
                      if (Number(uploadFormType) === 0) {
                        setUploadFormType(1);
                      } else {
                        setUploadFormType(0);
                      }
                    }}
                  >
                    <CIcon name="cil-sync" />
                    <span className="mx-1" />
                    {Number(uploadFormType) === 0 ? 'Epub Upload' : 'Image Upload'}
                  </CButton>
                )}
              </CButtonToolbar>

              {onSortSave !== undefined && (
                <CButton type="submit" size="sm" color="info" onClick={onSortSave}>
                  <CIcon name="cil-scrubber" /> 이미지 정렬 저장
                </CButton>
              )}
            </CButtonToolbar>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <>
          <CCol xs="12" md="12" className={`${tag}-image__container`}>
            <SortItemBox
              tag={tag}
              token={token}
              storedItems={imageContent}
              setStoredItem={setImagContent}
              requestBody={removeRequest}
              removeUrl={removeUrl}
            />
          </CCol>
          {Number(uploadFormType) === 0 ? (
            <CCol xs="12">
              <ImageUploadV2
                token={token}
                updateItem={setImagContent}
                onUploadComplete={onUploadComplete}
                requestForm={requestForm}
                callbackUrl={uploadUrl}
              />
            </CCol>
          ) : (
            <CCol xs="12">
              <FileUpload
                token={token}
                updateItem={setImagContent}
                requestForm={fileUploadRequestForm}
                callbackUrl={uploadUrl}
              />
            </CCol>
          )}
        </>
      </CCardBody>
    </CFormGroup>
  );
};

export default EpisodeImageUploadForm;
