import UserDefault from 'lib/UserDefault';

const categoryBit = label => UserDefault.bitList.find('permission', label);

// export function funcTransI18N(value) {
//   return <Translation>{(t, { i18n }) => <span>{t(value)}</span>}</Translation>;
// }

const Nav = () => {
  return [
    // ANCHOR 만화
    {
      title: 'category_comic',
      children: [
        {
          title: 'category_new_regist',
          to: '/comics/new/0',
          permissionBit: categoryBit('만화/에피소드/캐릭터/글로벌 설정'),
          icon: 'cil-plus',
        },
        {
          title: 'category_comic',
          permissionBit: categoryBit('만화/에피소드/캐릭터/글로벌 설정'),
          to: '/comics/all',
        },
        {
          title: '당일 연재 일정',
          permissionBit: categoryBit('만화/에피소드/캐릭터/글로벌 설정'),
          to: '/comics/todayOpen',
        },
        {
          title: 'category_author',
          to: '/author/list',
          permissionBit: categoryBit('작가/키워드'),
        },
        {
          title: 'category_genres_keyword',
          permissionBit: categoryBit('작가/키워드'),
          to: '/promotion/hashtag',
        },
        {
          title: 'category_caracter_info',
          permissionBit: categoryBit('만화/에피소드/캐릭터/글로벌 설정'),
          children: [
            {
              title: 'category_caracter_tag',
              to: '/characterTag/list',
            },
            {
              title: 'category_caracter_list',
              to: '/character/list',
            },
          ],
        },

        {
          title: 'category_genres_Searchkeyword',
          permissionBit: categoryBit('작가/키워드'),
          to: '/searchKeyword/list',
        },
        {
          title: 'category_theme',
          permissionBit: categoryBit('테마관'),
          to: '/theme/1',
        },
        {
          title: 'category_special',
          permissionBit: categoryBit('기획전'),
          to: '/promotion/eventTheme',
        },
        {
          title: 'category_promotion_banner',
          permissionBit: categoryBit('만화/에피소드/캐릭터/글로벌 설정'),
          to: '/promotionPeriod',
        },
        {
          title: 'category_comic_curation',
          permissionBit: categoryBit('만화/에피소드/캐릭터/글로벌 설정'),
          to: '/comiccuration/list',
        },
      ],
    },
    // ANCHOR 연재
    {
      title: 'category',
      permissionBit: categoryBit('카테고리'),
      children: [
        {
          title: 'category_main',
          children: [
            {
              title: 'category_mon',
              to: '/category/daily/mon',
            },
            {
              title: 'category_tue',
              to: '/category/daily/tue',
            },
            {
              title: 'category_wed',
              to: '/category/daily/wed',
            },
            {
              title: 'category_thu',
              to: '/category/daily/thu',
            },
            {
              title: 'category_fri',
              to: '/category/daily/fri',
            },
            {
              title: 'category_sat',
              to: '/category/daily/sat',
            },
            {
              title: 'category_sun',
              to: '/category/daily/sun',
            },
            {
              title: 'category_top',
              to: '/category/daily/popular',
            },
          ],
        },
        {
          title: 'category_complete',
          to: '/category/complete',
        },
        {
          title: 'category_popular',
          to: '/category/popular',
        },
        {
          title: 'category_new',
          to: '/category/new',
        },
        {
          title: 'category_genres',
          to: '/genres',
          permissionBit: categoryBit('작가/키워드'),
        },
        {
          title: 'category_mature',
          to: '/category/mature',
        },
        {
          title: 'category_new_series',
          to: '/category/new_series',
        },
        {
          title: 'Artworks',
          to: '/artworks',
          permissionBit: categoryBit('S갤러리'),
        },
        {
          title: 'PROMO',
          to: '/promo',
        },
        {
          title: 'category_origin_free',
          to: '/category/originFree',
        },
        {
          title: 'category_manga',
          to: '/category/manga',
        },
      ],
    },
    // ANCHOR 배너
    {
      title: 'category_banner',
      permissionBit: categoryBit('배너'),
      children: [
        {
          title: 'category_main',
          to: '/banner/main_v2',
        },
        {
          title: 'category_event',
          to: '/banner/event',
        },
        {
          title: 'category_floating',
          to: '/banner/floating',
        },
        {
          title: 'category_user_banner',
          to: '/userBanner/all',
        },
        {
          title: 'category_random_user_banner',
          to: '/randomUserBanner/all',
        },
        {
          title: '액션 레이어 관리',
          to: '/actionLayer',
        },
        {
          title: 'category_main_center',
          to: '/banner/theme',
        },
        {
          title: 'category_logo',
          to: '/logoList/1',
        },
        {
          title: 'category_payment',
          to: '/paymentBanner',
        },
      ],
    }, // ANCHOR 프로모션
    {
      title: 'category_promotion',
      children: [
        {
          title: 'category_giftbox',
          permissionBit: categoryBit('굿즈/선물함'),
          children: [
            {
              title: '선물함 리스트',
              permissionBit: categoryBit('굿즈/선물함'),
              to: '/giftbox',
            },
            {
              title: '레이어 선물함 리스트',
              permissionBit: categoryBit('굿즈/선물함'),
              to: '/layerGiftbox',
            },
          ],
        },
        {
          title: '등급 설정',
          permissionBit: categoryBit('굿즈/선물함'),
          to: '/grade',
        },
        {
          title: 'category_invite_reward',
          permissionBit: categoryBit('굿즈/선물함'),
          to: '/giftbox/inviteRewardList',
        },
        {
          title: 'category_goods_list',
          permissionBit: categoryBit('굿즈/선물함'),
          to: '/promotion/goods/ticket',
        },
        {
          title: 'category_all_keep',
          permissionBit: categoryBit('전체 소장'),
          to: '/promotion/unlock_all_discount',
        },
        {
          title: 'category_all_keep_Individual',
          permissionBit: categoryBit('전체 소장'),
          to: '/promotion/unlock_all_discount_list',
        },
        {
          title: 'category_event_list',
          permissionBit: categoryBit('이벤트'),
          to: '/event/list',
        },
        {
          title: 'category_episode_induce',
          permissionBit: categoryBit('회차 유도'),
          to: '/promotion/induce/all',
        },
        {
          title: 'dailyreward(룰렛)',
          permissionBit: categoryBit('룰렛'),
          to: '/event/daily/reward/list',
        },
        {
          title: '이상형 월드컵',
          permissionBit: categoryBit('이벤트'),
          to: '/promotion/idealWorldCup/list',
        },
        {
          title: '전자 캘랜더',
          permissionBit: categoryBit('이벤트'),
          to: '/promotion/eCalendar/list',
        },
        {
          title: '마케팅 만화 설정', // 글로벌만 사용.
          children: [
            {
              title: 'Traffic Junky',
              permissionBit: categoryBit('만화/에피소드/캐릭터/글로벌 설정'),
              to: '/promotion/marketing/trafficJunky',
            },
          ],
        },
      ],
    },
    // ANCHOR 결제 상품
    {
      title: 'category_payment_product',
      permissionBit: categoryBit('결제 상품'),
      children: [
        {
          title: 'category_payment_product_list',
          to: '/paymentList',
        },
        {
          title: 'category_payment_method_list',
          to: '/paymentMethod',
        },
        {
          title: 'category_country_currencies_select',
          permissionBit: categoryBit('지역별 화폐'),
          to: '/localeCurrency',
        },
        {
          title: 'category_product_manage',
          to: '/productGroup',
        },
      ],
    },
    // ANCHOR 유저 정보
    {
      title: 'category_user',
      children: [
        {
          title: 'category_user_list',
          to: '/users',
          permissionBit: categoryBit('결제/회원'),
        },
        {
          title: 'category_user_block_list',
          to: '/userblock',
          permissionBit: categoryBit('결제/회원'),
        },
        {
          title: 'category_user_visitor_block_list',
          to: '/visitoridBlock',
          permissionBit: categoryBit('결제/회원'),
        },
        {
          title: 'category_user_notice',
          to: '/notice',
          permissionBit: categoryBit('앱푸쉬/공지사항/FAQ'),
        },
        {
          title: 'FAQ',
          to: '/faq',
          permissionBit: categoryBit('앱푸쉬/공지사항/FAQ'),
        },
        {
          title: 'category_report_inquiry',
          to: '/userSubmit',
          permissionBit: categoryBit('결제/회원'),
        },
        {
          title: '댓글 관리',
          children: [
            {
              title: '댓글 필터',
              to: '/users/comment/filter',
              permissionBit: categoryBit('결제/회원'),
            },
            {
              title: '댓글 검수',
              to: '/users/comment/list',
              permissionBit: categoryBit('결제/회원'),
            },
          ],
        },
      ],
    }, // ANCHOR 앱
    {
      title: 'category_app',
      children: [
        {
          title: 'category_app_push',
          permissionBit: categoryBit('앱푸쉬/공지사항/FAQ'),
          to: '/app/push',
        },
        {
          title: 'category_app_upload',
          permissionBit: categoryBit('파일 업로드(앱 파일)'),
          to: '/app/upload',
        },
        {
          title: '앱 버전관리', // TODO: 번역
          permissionBit: categoryBit('앱 버전'),
          to: '/app/version',
        },
      ],
    },
    // ANCHOR 통계
    {
      title: 'category_statistics',
      permissionBit: categoryBit('통계'),
      children: [
        // ANCHOR 통계 - 코인
        {
          title: 'category_coin',
          children: [
            {
              title: 'category_acc_coin',
              to: '/statistics/v2/purchase/acc',
            },
            {
              title: 'category_date_coin',
              to: '/statistics/v2/purchase/day',
            },
            {
              title: 'category_time_coin',
              to: '/statistics/v2/purchase/time',
            },
          ],
        }, // ANCHOR 통계 - 매출
        {
          title: 'category_sales',
          children: [
            {
              title: 'category_all_payment',
              to: '/statistics/v2/payment/all',
            },
            {
              title: 'category_auto_charge_payment',
              to: '/statistics/v2/payment/recurring',
            },
            {
              title: 'category_time_payment',
              to: '/statistics/v2/payment/time',
            },
            {
              title: 'category_daily_payment',
              to: '/statistics/v2/payment/day',
            },
            {
              title: 'category_daily_firtst_payment',
              to: '/statistics/v2/payment/day/first',
            },
            {
              title: 'category_country_currencies',
              to: '/statistics/v2/payment/country/country',
            },
            {
              title: 'category_discount_coupon',
              to: '/statistics/v2/payment/discount',
            },
            {
              title: 'category_auto_charge_membership',
              to: '/statistics/v2/payment/membership',
            },
            {
              title: 'category_payment_renewal',
              to: '/statistics/v2/payment/sync',
            },
            {
              title: 'category_performance_product',
              to: '/statistics/v2/performance/product',
            },
          ],
        }, // ANCHOR 통계 - 작품
        {
          title: 'category_work',
          children: [
            {
              title: 'category_comic_by_date',
              to: '/statistics/v2/comic/info',
            },
            {
              title: 'category_comic_by_time',
              to: '/statistics/v2/comic/time',
            },
            {
              title: 'category_episode',
              to: '/statistics/v2/comic/episode',
            },
            {
              title: 'category_recent_episode_sales',
              to: '/statistics/v2/comic/continueRead',
            },

            {
              title: 'category_all_buy_daily',
              to: '/statistics/v2/collection/day/total',
            },
            {
              title: 'category_all_buy_work',
              to: '/statistics/v2/collection/comic/total',
            },
            {
              title: 'category_free_episode_keep',
              to: '/statistics/v2/collection/free/purchase',
            },
            {
              title: '뷰어 유료회차 소장',
              to: '/statistics/v2/collection/paid/purchase/comic',
            },
            {
              title: 'category_auto_subscribe',
              to: '/statistics/v2/comic/autoPurchase',
            },
            {
              title: '기획전',
              to: '/statistics/v2/collection/eventTheme',
            },
            {
              title: '기다빵(일별)',
              to: '/statistics/v2/collection/waitFreeComicDay',
            },
            {
              title: '추천무료(일별)',
              to: '/statistics/v2/collection/recommendFreeDay',
            },
            {
              title: '만화 - 치라미코인(일별)',
              to: '/statistics/v2/collection/comic/chiramiCoinByDate',
            },
            {
              title: 'category_new_open_statistics',
              to: '/statistics/v2/comic/firstOpenComic',
            },
            {
              title: 'category_performance_daily_comic',
              to: '/statistics/v2/performance/comicDaily',
            },
            {
              title: '후원하기',
              to: '/statistics/v2/comic/donation/all',
            },
            {
              title: '후원하기 - 작품별',
              to: '/statistics/v2/comic/donation/comic',
            },
          ],
        },
        // ANCHOR 통계 - 회원
        {
          title: 'category_member',
          _tag: 'CSidebarNavDropdown',
          children: [
            {
              title: 'category_signup_signin',
              to: '/statistics/v2/member/sign_in',
            },
            {
              title: 'category_signup_country',
              to: '/statistics/v2/member/signup/country',
            },

            {
              title: 'category_signup_time',
              to: '/statistics/v2/member/signUp/time',
            },
            {
              title: 'category_signin_country',
              to: '/statistics/v2/member/signin/country',
            },
            {
              title: 'category_signin_time',
              to: '/statistics/v2/member/signIn/time',
            },
            {
              title: 'category_pre_auth',
              to: '/statistics/v2/member/already_mature',
            },
            {
              title: 'category_auto_auth',
              to: '/statistics/v2/member/autoAuth',
            },
            {
              title: 'category_group',
              to: '/statistics/v2/member/user_group',
            },
            {
              title: '회원 등급 통계',
              to: '/statistics/v2/member/user_grade',
            },
            {
              title: 'category_statistics_block_user',
              to: '/statistics/v2/member/block',
            },
            {
              title: '방문자 통계',
              to: '/statistics/v2/member/visitor',
            },
          ],
        },
        // ANCHOR 통계 - 프로모션
        {
          title: 'category_promotion',
          children: [
            {
              title: 'category_statistics_promotion_giftbox',
              to: '/statistics/v2/giftbox/day',
            },
            {
              title: 'category_statistics_promotion_seasonpass',
              to: '/statistics/v2/promotion/seasonPass',
            },
            {
              title: 'category_statistics_promotion_coupon',
              to: '/statistics/v2/promotion/coupon',
            },
            {
              title: 'category_statistics_promotion_onetime_coupon',
              to: '/statistics/v2/promotion/oneTimeCoupon',
            },
            {
              title: 'category_statistics_promotion_inflow_by_cate',
              to: '/statistics/v2/promotion/InflowByCate',
            },
            {
              title: 'dailyreward(룰렛)',
              to: '/statistics/v2/promotion/dailyRewardList',
            },
            {
              title: '굿즈 코인 통계',
              to: '/statistics/v2/goods/coin',
            },
            {
              title: '친구초대 통계(일별)',
              to: '/statistics/v2/invite/day',
            },
          ],
        },
        // ANCHOR 통계 - 프로모션(파트너코드)
        {
          title: 'category_partner',
          children: [
            {
              title: 'category_partner_code',
              to: '/partnercode',
              permissionBit: categoryBit('파트너 코드'),
            },
            {
              title: 'category_partner_statistics',
              to: '/statistics/v2/promotion/code',
            },
            // TODO: 무충소 보류로 비노출 처리, 진입은 가능
            // {
            //   title: '무료충전소 통계',
            //   to: '/statistics/v2/partnerMission',
            // },
          ],
        },
        {
          title: 'category_app',
          children: [
            {
              title: 'category_app_download',
              permissionBit: categoryBit('통계'),
              to: '/statistics/v2/app/installStat',
            },
          ],
        },

        {
          title: '기타',
          children: [
            {
              title: '페이지별 유입 통계',
              permissionBit: categoryBit('통계'),
              to: '/statistics/v2/etc/pageHitsCount',
            },
          ],
        },
      ],
    },
    {
      title: 'category_account_controll',
      permissionBit: categoryBit('관리자 계정 권한'),
      children: [
        {
          title: 'category_account_controll_account_list',
          to: '/master/userList',
        },
        {
          title: 'category_account_control_signup_apply_list',
          to: '/master/signupList',
        },

        {
          title: 'category_account_control_inactive_apply_list',
          to: '/master/disabledUserList',
        },
      ],
    },
    {
      title: 'category_etc',
      children: [
        {
          title: 'category_setting',
          to: '/preferences',
        },
        {
          title: 'category_history',
          to: '/editLog',
        },
        {
          title: 'category_config',
          to: '/config',
          permissionBit: categoryBit('만화/에피소드/캐릭터/글로벌 설정'),
        },
      ],
    },
  ];
};

export default Nav;
