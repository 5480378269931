/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { createSlice } from '@reduxjs/toolkit';

export const name = 'ImgSlice';

const initialState = {
  imageContents: [],
  imageContentsUpload: false,
  imageContentsUploadCount: 0,
  episodeBanners: [],
  episode17Banners: [],
  comicThumbnails: [],
  mobildLandscapeThumbs: [],
};

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    setImageContents: (state, action) => {
      state.imageContents.push(action.payload);
    },
    updateImageContents: (state, action) => {
      state.imageContents = action.payload;
    },
    setComicThumbnails: (state, action) => {
      state.comicThumbnails.push(action.payload);
    },
    updateComicThumbnails: (state, action) => {
      state.comicThumbnails = action.payload;
    },
    setMobildLandscapeThumbs: (state, action) => {
      state.mobildLandscapeThumbs.push(action.payload);
    },
    updateMobildLandscapeThumbs: (state, action) => {
      state.mobildLandscapeThumbs = action.payload;
    },
    setEpisodeBanners: (state, action) => {
      state.episodeBanners.push(action.payload);
    },
    updateEpisodeBanners: (state, action) => {
      state.episodeBanners = action.payload;
    },
    setEpisode17Banners: (state, action) => {
      state.episode17Banners.push(action.payload);
    },
    updateEpisode17Banners: (state, action) => {
      state.episode17Banners = action.payload;
    },
    clearData: state => {
      state = initialState;
    },
    startContentsUpload: (state, action) => {
      state.imageContentsUpload = true;
    },
    endContentsUpload: state => {
      state.imageContentsUpload = false;
      state.imageContentsUploadCount = 0;
    },
    increaseContentsUploadCount: state => {
      if (state.imageContentsUpload === false) {
        state.imageContentsUpload = true;
      }
      state.imageContentsUploadCount++;
    },
    decreaseContentsUploadCount: (state, action) => {
      state.imageContentsUploadCount--;
      if (state.imageContentsUploadCount <= 0) {
        state.imageContentsUploadCount = 0;
        state.imageContentsUpload = false;
      }
    },
  },
});

export const {
  clearData,
  setImageContents,
  updateImageContents,
  setComicThumbnails,
  updateComicThumbnails,
  setMobildLandscapeThumbs,
  updateMobildLandscapeThumbs,
  setEpisodeBanners,
  updateEpisodeBanners,
  setEpisode17Banners,
  updateEpisode17Banners,
  startContentsUpload,
  endContentsUpload,
  increaseContentsUploadCount,
  decreaseContentsUploadCount,
} = slice.actions;

export default slice.reducer;
