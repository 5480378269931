import { createSlice } from '@reduxjs/toolkit';

export const name = 'comment';

const initialState = {
  dayComicsCommentCnt: null,
  jpCommentCnt: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateDayComics: (state, action) => {
      state.dayComicsCommentCnt = action.payload;
    },
    updateJp: (state, action) => {
      state.jpCommentCnt = action.payload;
    },
  },
});

export const { updateDayComics, updateJp } = slice.actions;

export default slice.reducer;
