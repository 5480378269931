import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './slices/';

const persistConfig = {
  key: 'GTA',
  storage,
  whitelist: ['user', 'toast', 'view', 'button', 'common', 'comment', 'comicSync'],
};

export default function ReduxManager() {
  const bindMiddleware = () => {
    const thunks = getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    });
    if (process.env.NODE_ENV === 'development') {
      const logger = createLogger();
      return [...thunks, logger];
    }
    return [...thunks];
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: bindMiddleware(),
  });
  const persistor = persistStore(store);
  return { store, persistor };
}
