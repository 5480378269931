import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CButtonGroup,
} from '@coreui/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export type ModalItem = {
  key: string;
  buttonType: 'alert' | 'confirm' | 'delete' | 'error';
  onClick?: () => void;
  onClose?: () => void;
  styles?: object;
  size?: '' | 'sm' | 'lg' | 'xl';
  timeoutClose?: number; // 모달 밀리초 이후 자동닫기
  isCenter?: boolean; // 모달 중앙정렬
  isFooter?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  info: { title: string; description?: string }; // 모달 헤더,내용
  className?: string;
  closeButton?: boolean;
  originComponent?: JSX.Element;
  component?: JSX.Element;
};

const createComponent = ({
  key,
  info,
  onClick,
  onClose,
  timeoutClose,
  buttonType = 'alert',
  isCenter = false,
  isFooter = true,
  size = '',
  shouldCloseOnOverlayClick = true,
  closeButton = true,
  className,
  component,
}: ModalItem): React.ReactElement => {
  const onCloseDefault = (e: any) => {
    ModalInstance.getInstance().delete(key);
  };
  // 설정시 모달 몇초후 자동닫기
  const timeout = () =>
    setTimeout((e: any) => {
      onCloseDefault(e);
    }, timeoutClose);
  clearTimeout(timeout());
  if (timeoutClose) {
    timeout();
  }
  if (!info.description && !component) {
    throw new Error('info.description 또는 component 중 하나를 정의해주세요');
  }

  const onClickCallback = (e: React.MouseEvent) => {
    if (onClick) {
      onClick();
    }
    if (onClose) {
      onClose();
    }
    onCloseDefault(e);
  };

  const onCloseCallback = (e: React.MouseEvent) => {
    onCloseDefault(e);
    if (onClose) {
      onClose();
    }
  };

  const Button = () => {
    if (buttonType === 'alert') {
      return (
        <CButton color="info" onClick={onClickCallback}>
          확인
        </CButton>
      );
    } else if (buttonType === 'error') {
      return (
        <CButton color="danger" onClick={onClickCallback}>
          확인
        </CButton>
      );
    } else if (buttonType === 'confirm') {
      return (
        <CButtonGroup>
          <CButton color="info" onClick={onClickCallback}>
            확인
          </CButton>
          <CButton color="light" onClick={onCloseDefault}>
            취소
          </CButton>
        </CButtonGroup>
      );
    } else if (buttonType === 'delete') {
      return (
        <CButtonGroup>
          <CButton color="danger" onClick={onClickCallback}>
            삭제
          </CButton>
          <CButton color="light" onClick={onCloseDefault}>
            취소
          </CButton>
        </CButtonGroup>
      );
    } else {
      return <></>;
    }
  };

  return (
    <CModal
      centered={isCenter}
      key={key}
      className={className}
      show
      size={size}
      closeOnBackdrop={shouldCloseOnOverlayClick}
      onClose={onCloseCallback}
    >
      <CModalHeader closeButton={closeButton}>
        <strong>{info.title}</strong>
      </CModalHeader>
      <CModalBody>{component || info.description}</CModalBody>
      {isFooter && (
        <CModalFooter>
          <Button />
        </CModalFooter>
      )}
    </CModal>
  );
};

const checkDuplicate = (items: ModalItem[], compareKey: string) => {
  const result = _.filter(items, { key: compareKey });
  return result.length > 0;
};

export class ModalInstance {
  private static instance: ModalInstance;
  private modal: BehaviorSubject<ModalItem[]> | undefined;

  public static getInstance(): ModalInstance {
    return this.instance || (this.instance = new this());
  }

  public create(): BehaviorSubject<ModalItem[]> {
    this.modal = new BehaviorSubject<ModalItem[]>([]);
    return this.modal;
  }

  public push(modal: ModalItem) {
    const { key } = modal;
    if (this.modal) {
      const current: ModalItem[] = this.modal.getValue();

      // 중복 제외 처리
      if (checkDuplicate(current, key)) {
        return;
      }

      this.modal.next(
        this.modal.value.concat({
          ...modal,
          originComponent: modal.component,
          component: createComponent(modal),
        }),
      );
      return this.modal;
    }
  }

  public delete(key: string) {
    if (this.modal) {
      const modals: ModalItem[] = this.modal.getValue();
      _.remove(modals, n => {
        return n.key === key;
      });
      this.modal.next([...modals]);
    }
  }

  /**
   * close all modal
   */
  public reset() {
    if (this.modal) {
      this.modal.next([]);
    }
  }
}

const ModalContainer = (props: any) => {
  const [modalList, setModalList] = useState<ModalItem[]>([]);

  useEffect(() => {
    const subscriber = ModalInstance.getInstance().create();
    subscriber.subscribe({
      next: (v: Array<ModalItem>) => {
        setModalList(v);
      },
    });

    // if (onLoad) {
    //   onLoad();
    // }

    return function cleanup() {
      subscriber.complete();
    };
  }, []);
  return (
    <>
      {modalList.map(item => {
        return item.component;
      })}
    </>
  );
};

export default ModalContainer;
