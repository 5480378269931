import { CCol, CFormGroup, CFormText, CLabel, CSelect } from '@coreui/react';

import { CSSProperties } from 'styled-components';
import React from 'react';

interface SelectBoxType {
  value: any;
  onChange: (t: any) => void;
  options: any[];
  name?: string;
  className?: any;
  formText?: any;
  style?: CSSProperties;
  disabled?: boolean;
}

/**
 * Select Box Version2 Item
 * @value : select value
 * @onChange : onChange Listener
 * @options : option value (string) 배열
 * @constructor created by jadejun 2021.05.14
 */
const SelectBoxV2 = ({
  value,
  name,
  onChange,
  options,
  className,
  formText,
  style,
  disabled = false,
}: SelectBoxType) => {
  return (
    <div className={className || 'ml-3 mr-4'} style={style}>
      <CSelect
        name={name || `select-box`}
        id={`select-box`}
        onChange={onChange}
        disabled={disabled}
        value={value}
      >
        {options.map((c: React.ReactNode | any, index: number) => {
          // c.value가 0 또는 '' 이면 오류
          // if (typeof c === 'object' && c?.value) {

          if (typeof c === 'object') {
            return (
              <option key={index} value={c.value}>
                {c.label || c.value}
              </option>
            );
          } else {
            return (
              <option key={index} value={index}>
                {c}
              </option>
            );
          }
        })}
      </CSelect>
      {formText !== undefined && <CFormText color="muted">{formText}</CFormText>}
    </div>
  );
};

export default SelectBoxV2;
