import { createSlice } from '@reduxjs/toolkit';
export const name = 'commonSlice';

const initialState = {
  languageList: [],
  language: '',
  multiLanguage: [],
  hostnameList: [],
  hostname: '',
};

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    updateTabList: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        if (_.has(action.payload, key)) {
          state[key] = action.payload[key];
        }
      });
    },
    clearCommonList: (state, action) => {
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    changeLanguage: (state, action) => {
      state.language = action.payload.language;
    },
    changeMultiLanguage: (state, action) => {
      let list = _.flatten([state.multiLanguage, action.payload.language]);
      list = list.filter((v, i) => list.indexOf(v) === i);
      console.log(list);
      if (list.length > 1 && state.multiLanguage.includes(action.payload.language)) {
        {
          state.multiLanguage = list.filter(v => v != action.payload.language);
        }
      } else {
        state.multiLanguage = list;
      }
    },
    changeHostname: (state, action) => {
      state.hostname = action.payload.hostname;
    },
  },
  extraReducers: {},
});

export const {
  updateTabList,
  changeLanguage,
  changeMultiLanguage,
  changeHostname,
  clearCommonList,
} = slice.actions;

export default slice.reducer;
