import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BiArrowToLeft } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import QueryString from 'qs';
import _ from 'lodash';
import useResetPassword from '../resetPassword/hooks/useResetPassword';
import { ResetPasswordType } from '@toptoon-developers/global.toptoonplus.common.lib/dist/api/admin/ApiAuth';
import InputField from 'components/FormiknputField';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { alertModal } from 'lib/Modals/alertModal';

interface ResetPasswordFormikType {
  password?: string;
  modifyPassword: string;
  re_modifyPassword: string;
}

const ResetPassword = () => {
  const history = useHistory();
  const queryData = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const userId = _.has(queryData, 'userId') ? queryData.userId : null;
  const resetTokenCheck = _.has(queryData, 'token') ? queryData.token : null;
  const { verifyResetToken, resetPassword } = useResetPassword();
  const [resetToken, setResetToken] = useState<string>('');

  useEffect(() => {
    if (resetTokenCheck) {
      verifyResetToken(
        { resetToken: resetTokenCheck.toString() },
        {
          onComplete: data => {
            setResetToken(data.resetToken);
          },
          onError: err => {
            if (err.response.data.error.includes('token')) {
              return alertModal('ERROR', {
                msg: '유효한 토큰이 아닙니다. 관리자에게 문의해주세요.',
                onClick: () => {},
                onClose: () => {},
              });
            }
          },
        },
      );
    }
  }, []);

  const formik: FormikProps<ResetPasswordFormikType> = useFormik<ResetPasswordFormikType>({
    initialValues: {
      password: '',
      modifyPassword: '',
      re_modifyPassword: '',
    },
    validationSchema: Yup.object({
      password: resetToken ? Yup.string() : Yup.string().required('비밀번호를 입력해주세요.'),
      modifyPassword: Yup.string().required('변경하실 비밀번호를 입력해주세요.'),
      re_modifyPassword: Yup.string()
        .oneOf([Yup.ref('modifyPassword')], '입력하신 비밀번호가 서로 다릅니다.')
        .required('변경하실 비밀번호를 한번 더 입력해주세요.'),
    }),
    onSubmit: values => {
      if (userId) {
        const request: ResetPasswordType = {
          loginId: userId.toString(),
          modifyPassword: values.modifyPassword,
        };
        if (resetToken) {
          request.resetToken = resetToken;
        } else {
          request.currentPassword = values.password;
        }
        resetPassword(request, {
          onComplete: data => {
            console.log(data);
            return alertModal('SAVE_AFTER', {
              msg: '비밀번호가 변경되었습니다.',
              onClick: () => {
                return history.push('/');
              },
              onClose: () => {
                return history.push('/');
              },
            });
          },
          onError: err => {
            if (err.response.data.error.includes('token')) {
              return alertModal('ERROR', {
                msg: '유효한 토큰이 아닙니다. 관리자에게 문의해주세요.',
                onClick: () => {},
                onClose: () => {},
              });
            } else if (err.response.data.error.includes('password fail')) {
              alertModal('ERROR', {
                msg: '현재 비밀번호가 맞지 않습니다. 비밀번호를 다시 확인해주세요.',
              });
            }
          },
        });
      }
    },
  });

  return (
    <ForgotpwStyled>
      <div className="header">
        <a href="/">
          <img alt="logo" src="/logos/logo.png" className="logo" />
        </a>
        <h2>비밀번호 변경</h2>
        <BiArrowToLeft
          onClick={() => {
            history.push('/login');
          }}
          className="back-btn"
        />
      </div>
      <div className="container">
        <div className="item-wrap">
          <div className="title">비밀번호 변경</div>
          <form className="form-wrap" onSubmit={formik.handleSubmit}>
            {!resetTokenCheck && (
              <InputField
                formik={formik}
                type="password"
                name="password"
                placeholder="이전 비밀번호 입력"
              />
            )}
            <InputField
              formik={formik}
              type="password"
              name="modifyPassword"
              placeholder="새 비밀번호 입력"
            />
            <InputField
              formik={formik}
              type="password"
              name="re_modifyPassword"
              placeholder="새 비밀번호 재입력"
            />
            <div className="submit-btn">
              <button type="submit" className="forgotpw-btn">
                비밀번호 변경
              </button>
            </div>
          </form>
        </div>
      </div>
    </ForgotpwStyled>
  );
};

export default ResetPassword;

const ForgotpwStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 60px;
    word-break: keep-all;
    background: #fff;
    /* margin-bottom: 30px; */
    box-shadow: 0 1px 1px 0 rgba(66, 71, 80, 0.14), 0 2px 1px -1px rgba(66, 71, 80, 0.12),
      0 1px 3px 0 rgba(66, 71, 80, 0.2);

    .logo {
      @media (max-width: 620px) {
        display: none;
      }
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
      height: 36px;
    }
    > h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
    }
    .back-btn {
      position: absolute;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      height: 36px;
      width: 30px;
    }
  }
  .container {
    flex: 0 0 99%;
    max-width: 760px;
    align-items: center;
    justify-content: center;
  }

  .item-wrap {
    padding: 2.75rem;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(66, 71, 80, 0.14), 0 2px 1px -1px rgba(66, 71, 80, 0.12),
      0 1px 3px 0 rgba(66, 71, 80, 0.2);
    @media (max-width: 767px) {
      padding: 1.5rem;
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    color: #333;

    @media (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  .form-wrap {
    margin-top: 20px;
  }

  .form-control {
    border: solid 1px #e4e7ea;
  }

  .form-control:focus {
    border: solid 1px #8ad4ee;
  }

  .forgotpw-btn {
    width: 100%;
    height: 2.5rem;
    color: #fff;
    border: none;
    /* border-radius: 6px; */
    background-color: #e55353;
    font-weight: 600;
  }
`;
